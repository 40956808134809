import React from "react";

export const NoWalletNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="noWalletNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#noWalletNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>No wallet found</h3>
                        <p>Try our Cardano wallet guide page.</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a href="https://nfty.property/how-to-buy/" target="_blank" rel="noopener noreferrer">Cardano wallet guide</a>
                </div>
            </div>
        </div>
    );
};