import React from "react";

export const UnlistedNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="unlistedNotification" style={{ display: 'none' }}>
            <div className="alart-area" style={{ backgroundColor: '#25a552' }}>
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.nfty.property/img/alart-black.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#unlistedNotification').fadeOut();
                            }} />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3 style={{ color: 'black' }}>Your NFT has been unlisted</h3>
                        <p style={{ color: 'black' }}>It will be sent back to your wallet shortly</p>
                    </div>
                </div>
            </div>
        </div>
    );
};