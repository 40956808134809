import React from "react";

export const PaymentPendingNotification: React.FC = () => {
    return (
        <div className="alart-s notification" id="paymentPendingWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#paymentPendingWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>Payment already pending</h3>
                        <p>Another payment for the NFT is already pending. Try again later</p>
                    </div>
                </div>
            </div>
        </div>
    );
};