import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import { Spinner } from '../common/Spinner';
import ReactPlayer from 'react-player';
// import { MyNoteListing } from '../listings/components/MyNoteListing';

interface NoteType {
    name: string;
    type: 'bronze' | 'silver' | 'gold' | 'platinum';
    conversion_time: string;
    contract: string;
    currency: {
        name: string;
        symbol: string;
    };
}

export const Note: React.FC = () => {

    const { noteId } = useParams<{ noteId: string }>();
    const navigate = useNavigate();
    const [note, setNote] = useState<NoteType | null>(null);
    const [loading] = useState<boolean>(false);

    const updateData = (): void => {
        fetch(API_URL + 'notes/' + noteId)
            .then((res) => res.json())
            .then((res: NoteType) => {
                setNote(res);
            })
            .catch((error) => {
                navigate('/myNfties');
            });
    };

    useEffect(() => {
        updateData();
        document.body.classList.add('test-ppi-bg');
        document.querySelectorAll('header')[0].classList.add('test-street-page');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVideoFileName = (type: NoteType['type']): string => {
        switch (type) {
            case 'bronze':
                return 'bronze_letterbox.mp4';
            case 'silver':
                return 'silver_cabin.mp4';
            case 'gold':
                return 'gold_blue_house.mp4';
            case 'platinum':
                return 'platinum_mansion.mp4';
            default:
                return '';
        }
    };

    const capitalizeFirstLetter = (string: string): string => {
        return string ? string[0].toUpperCase() + string.slice(1) : '';
    };

    const getInterestRate = (type: NoteType['type']): string => {
        switch (type) {
            case 'bronze':
                return '10%';
            case 'silver':
                return '12%';
            case 'gold':
                return '15%';
            case 'platinum':
                return '19%';
            default:
                return '';
        }
    };

    const getTerm = (type: NoteType['type']): string => {
        switch (type) {
            case 'bronze':
                return '12 months';
            case 'silver':
                return '12 months';
            case 'gold':
                return '18 months';
            case 'platinum':
                return '24 months';
            default:
                return '';
        }
    };

    if (!note) {
        return <div className={'text-center'}><Spinner /></div>;
    }

    return (
        <Fragment>
            {loading && <Spinner />}
            <div className="container">
                <div className="test-street-area">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-start">
                            <div className="test-light-box-img d-flex align-items-stretch">
                                <div className="w-100">
                                    <ReactPlayer
                                        className="card-img-top h-100"
                                        url={`https://marketplace.nfty.property/img/${getVideoFileName(note?.type)}`}
                                        playing muted loop playsinline
                                        title={`${capitalizeFirstLetter(note?.type)}`}
                                        width="100%"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-stretch order-first order-lg-last">
                            <div className="test-street-right-item w-100">
                                <div className="test-street-title">
                                    <h2><span style={{ fontWeight: 'bold' }}>{note.name}</span></h2>
                                </div>
                                <div className="nfty-info w-100">
                                    <p className="nfty-type" style={{ paddingTop: '10px' }}>nfty Type: <span style={{ fontWeight: 'bold' }}>{capitalizeFirstLetter(note?.type)}</span></p>
                                    <p className='conversion-date' style={{ paddingTop: '10px' }}>
                                        Conversion date:{' '}
                                        <span style={{ fontWeight: 'bold' }}>{new Date(note.conversion_time).toLocaleDateString()}</span>
                                    </p>
                                    <p className='interest-rate' style={{ paddingTop: '10px' }}>Interest Rate: <span style={{ fontWeight: 'bold' }}>{getInterestRate(note?.type)}</span></p>
                                    <p className='nfty-term' style={{ paddingTop: '10px' }}>Term: <span style={{ fontWeight: 'bold' }}>{getTerm(note?.type)}</span></p>
                                    <p className='interest-paid' style={{ paddingTop: '10px' }}>Paid: <span style={{ fontWeight: 'bold' }}>Monthly</span></p>
                                    <p style={{ paddingTop: '10px' }}><a href={"https://nftworld.mypinata.cloud/ipfs/" + note.contract} target="_blank" rel="noopener noreferrer" className="contract-details-link" style={{ fontWeight: 'bold' }}>Contract</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};