import React from "react";

export const MinimumPriceNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="minimumPriceWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.nfty.property/img/alart-red.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#minimumPriceWarning').fadeOut();
                            }} />
                    </div>
                    <div className="alart-content">
                        <h3>Price is below minimum</h3>
                        <p>Minimum sale price is 20 ADA</p>
                    </div>
                </div>
            </div>
        </div>
    );
};