/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface UnauthenticatedWarningProps {
    type: string;
}

export const UnauthenticatedWarning: React.FC<UnauthenticatedWarningProps> = ({ type }) => {

    let text: string;

    if (type === 'pledge') {
        text = 'You need to be logged in to make a pledge';
    } else if (type === 'sell') {
        text = 'You have to be logged in to sell a property';
    } else  {
        text = 'You need to be logged in to buy an NFT';
    }

    return (
        <div className="alart-s" id="unauthenticatedWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            style={{ cursor: 'pointer' }}
                            alt=""
                            onClick={() => {
                                $('#unauthenticatedWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>Unauthenticated</h3>
                        <p>{text}</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            $('#loginModal').modal('show');
                        }}
                    >
                        Log in
                    </a>
                </div>
            </div>
        </div>
    );
};