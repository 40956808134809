import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { API_URL } from "../../config";
import { useParams } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";

interface ResetProps { }

interface ResetFormValues {
    password: string;
    repeatPassword: string;
}

export const Reset: React.FC<ResetProps> = () => {
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);

    const { code } = useParams<{ code: string }>();

    useEffect(() => {
        axiosInstance.get(API_URL + "auth/checkPasswordReset/" + code)
            .then((response) => {
                if (response.data?.status !== "ok") {
                    setInvalidCode(true);
                }
            })
            .catch(() => {
                setInvalidCode(true);
            });
    }, [code]);

    const onSubmit = async (values: ResetFormValues): Promise<void> => {
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        axiosInstance.post(API_URL + "auth/resetPassword/" + code, formData)
            .then((response) => {
            if (response.data?.status !== "ok") {
                setErrors(response.data.errors || []);
            } else {
                setErrors([]);
                setSuccess(true);
            }
        })
        .catch((error) => {
            console.error("Error resetting password:", error);
            setErrors(['An error occurred while resetting the password.']);
        });
    };

    if (invalidCode) {
        return (
            <div className="container">
                <div className="test-street-area">
                    <div className="alert alert-danger" role="alert">
                        Invalid or expired reset code
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="wlc-bg1">
            <HelmetProvider>
                <Helmet>
                    <title>nfty marketplace</title>
                    <meta name="description" content="Instant, borderless real estate - nfty" />
                    <meta property="og:title" content="nfty marketplace" />
                    <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                </Helmet>
            </HelmetProvider>
            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <span className="wel-page-left-arrow">
                            <img src="https://marketplace.nfty.property/img/img/us-left.png" className="img-fluid" alt="" />
                        </span>

                        <div className="sign-in-popup-form">
                            <div className="popup-form-title">
                                <h2>Welcome</h2>
                                <p style={{ textAlign: "left" }}>Choose a new password</p>
                            </div>
                            {errors.length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    {errors.map((error) => (
                                        <span key={"error" + error}>{error}</span>
                                    ))}
                                </div>
                            )}
                            {success && (
                                <div className="alert alert-success" role="alert">
                                    Your password has been changed, you can log in now!
                                </div>
                            )}
                            <Formik
                                initialValues={{ password: "", repeatPassword: "" }}
                                validate={(values: ResetFormValues) => {
                                    const errors: Partial<ResetFormValues> = {};

                                    if (!values.password) {
                                        errors.password = "Required";
                                    }

                                    if (!values.repeatPassword) {
                                        errors.repeatPassword = "Required";
                                    } else if (values.repeatPassword !== values.password) {
                                        errors.repeatPassword = "Passwords must match";
                                    }

                                    return errors;
                                }}
                                onSubmit={(values: ResetFormValues) => {
                                    onSubmit(values);
                                  }} >
                                {({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="password">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="password">
                                                            Password {<ErrorMessage name="password" component="span" className="text-danger" />}
                                                        </label>
                                                        <input {...field} type="password" name="password" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="repeatPassword">
                                            {({ field }) => (
                                                <div>
                                                    <div className="popup-sign-item">
                                                        <label htmlFor="repeatPassword">
                                                            Repeat password {<ErrorMessage name="repeatPassword" component="span" className="text-danger" />}
                                                        </label>
                                                        <input {...field} type="password" name="repeatPassword" className="form-value-color" id="asif-pl" placeholder="" />
                                                    </div>
                                                </div>
                                            )}
                                        </Field>

                                        <div className="popup-sign-in-submite wcl-btn">
                                            <button type="submit">Reset password</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};