/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {copyToClipboard} from "../../../helpers/clipboardHelper";

interface BuyOtherModalProps {
    qrCode: string;
    wallet: string;
    price: string;
    expiry: number;
    exchangeWarning?: boolean
}

export const BuyOtherModal: React.FC<BuyOtherModalProps> = ({qrCode, wallet, price, expiry, exchangeWarning = true}) => {
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [countDown, setCountdown] = useState<NodeJS.Timeout | null>(null);
    const [expired, setExpired] = useState<boolean | null>(null);

    useEffect(() => {
        startTimer(15 * 60);
        $('#staticBackdrop').modal('show');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiry]);

    const startTimer = (duration: number): void => {
        if (countDown) {
            clearInterval(countDown);
        }

        let timer = duration;

        setCountdown(
            setInterval(() => {
                const minutes = parseInt(String(timer / 60), 10);
                const seconds = parseInt(String(timer % 60), 10);

                setMinutes(minutes);
                setSeconds(seconds);

                if (minutes < 1 && seconds < 1) {
                    if (countDown) {
                        clearInterval(countDown);
                    }
                    setCountdown(null);
                    setExpired(true);
                } else {
                    --timer;
                }
            }, 1000)
        );
    };

    const copyAddress = (): void => {
        const value = $('#walletAddress').html();
        copyToClipboard(value);
        //navigator.clipboard.writeText(value);
        $('#copied').fadeIn();
    };

    return (
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="staticBackdropLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-x"></i></a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img pt-4">
                            <img src={qrCode} className="img-fluid" alt=""/>
                        </div>
                        {!expired &&
                        <div className="timer-area">
                          <div className="time-box">
                            <h3>{minutes}</h3>
                            <p>MINUTES</p>
                          </div>
                          <div className="time-box box-one">
                            <h3>{seconds}</h3>
                            <p>SECONDS</p>
                          </div>
                        </div>
                        }
                        {expired && <div className="timer-area">
                          <span>Payment expired</span>
                        </div>}
                        <div className="send-address text-center">
                            <h2>Send {price} ADA to address</h2>
                            {exchangeWarning &&
                            <p><span>Do not use an exchange wallet</span> as otherwise it might get irrecoverably
                              lost <br/>
                            </p>}
                            <span id="walletAddress">{wallet}</span>
                            <a onClick={copyAddress}>Copy address</a>
                            <p id="copied" style={{display: 'none', color: 'black'}}>Copied to clipboard</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};