import React from "react";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
    const currentYear: number = new Date().getFullYear();

    return (
        <footer className={'d-flex flex-column justify-content-between'}>
            <div className="container">
                <div className="footer-items">
                    <p className="copyright d-lg-none d-block mb-lg-2 mb-4">
                        nfty makes every effort to know our customers and comply with international anti-money laundering (AML) regulations. Specific documents are required for account verification. © {currentYear} nfty.property on behalf of Squarely OU, Estonia.
                    </p>
                    <div className="footer-content1">
                        <Link to={'/'}><img src="https://nfty.property/wp-content/themes/nfty/img/f-logo.svg" className="img-fluid" alt="Footer Logo" width="65" height="36" /></Link>
                    </div>
                    <p className="copyright d-none d-lg-inline-block">
                        nfty makes every effort to know our customers and comply with international anti-money laundering (AML) regulations. Specific documents are required for account verification. © {currentYear} nfty.property on behalf of Squarely OU, Estonia.
                    </p>
                    <div className="footer-content2 project">
                        <ul className="d-flex align-items-center gap-3 justify-content-end">
                            <li>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbuynfty.com%2F&t=Check%20this%20out!%20NFTY%20-%20The%20World%E2%80%99s%20First%20FULLY%20Digital%20Property%20Marketplace" title="Facebook" target="_blank" rel="noopener noreferrer">
                                    <img src="https://marketplace.nfty.property/img/sicon1.png" className="img-fluid" alt="facebook icon" width="24" height="24" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/intent/tweet?text=Check%20this%20out!%20NFTY%20-%20The%20World%E2%80%99s%20First%20FULLY%20Digital%20Property%20Marketplace&url=https%3A%2F%2Fbuynfty.com%2F" title="Twitter" target="_blank" rel="noopener noreferrer">
                                    <img src="https://marketplace.nfty.property/img/sicon2.png" className="img-fluid" alt="twitter icon" width="24" height="24" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCUbLCrtcjVdJQgpCEedMLZw" title="YouTube" target="_blank" rel="noopener noreferrer">
                                    <img src="https://marketplace.nfty.property/img/sicon4.png" className="img-fluid" alt="youtube icon" width="30" height="30" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="support">
                        <a href="mailto:info@nfty.property">
                            <i className="fa-solid fa-headset"></i>
                            <span>Need Help?</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};