import React from "react";

export const NoNFTsNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="noNftsNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.nfty.property/img/alart-red.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#noNftsNotification').fadeOut();
                            }} />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3>No nfty NFTs found in the wallet</h3>
                        <p>Your nfty NFTs will show up here once you buy some</p>
                    </div>
                </div>
            </div>
        </div>
    );
};