import React, { Fragment, useEffect, useState } from 'react';
import { Country } from "../../models/Country";
import { API_URL } from "../../config";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormErrors } from "../../models/FormErrors";

interface AffiliteFormValues {
    name: string;
    company: string;
    email: string;
    areaCode: string;
    phone: string;
    country: number;
}

export const AffiliateForm: React.FC = () => {
    const [errors, setErrors] = useState<Error[]>([]);
    const [success, setSuccess] = useState<boolean>(false);

    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        fetch(API_URL + 'countries')
            .then((res) => res.json())
            .then((res: Country[]) => {
                setCountries(res);
            });
    }, []);

    //Taken from https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    const validateEmail = (email: string): boolean => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    };

    const initialValues: AffiliteFormValues = {
        name: '',
        company: '',
        email: '',
        areaCode: '',
        phone: '',
        country: 1, 
    };

    const onSubmit = async (values: AffiliteFormValues): Promise<void> => {

        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        fetch(API_URL + 'affiliate', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(values => {
                if (values?.status !== 'ok') {
                    setSuccess(false);
                    setErrors(values);
                } else {
                    setErrors([]);
                    setSuccess(true);
                }
            });
    };

    return (
        <Fragment>
            {errors.length > 0 && (
                <div className="alert alert-danger" role="alert">
                    {errors.map(error => (
                        <span key={'error' + error.message}>{error.message}</span>
                    ))}
                </div>
            )}
            {success && (
                <div className="alert alert-success" role="alert">
                    Thank you, we've received your interest. We'll contact you soon!
                </div>
            )}
            <Formik
                initialValues={initialValues}
                validate={(values: AffiliteFormValues) => {
                    const requiredFields = ['name', 'areaCode', 'email', 'phone', 'company'];
                    const errors: Partial<FormErrors> = {};

                    requiredFields.forEach(field => {
                        if (!values[field]) {
                            errors[field] = 'Required';
                        }
                    });

                    if (!values.email) {
                        errors.email = 'Required';
                    } else {
                        if (!validateEmail(values.email)) {
                            errors.email = 'Invalid e-mail';
                        }
                    }
                    return errors;
                }}
                onSubmit={onSubmit} 
                >
                {({ handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="name">
                            {({ field }) => (
                                <div>
                                    <div className="popup-sign-item">
                                        <label htmlFor="name">
                                            Your name {<ErrorMessage name="name" component="span" className="text-danger" />}
                                        </label>
                                        <input {...field} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                    </div>
                                </div>
                            )}
                        </Field>

                        <Field name="company">
                            {({ field }) => (
                                <div>
                                    <div className="popup-sign-item">
                                        <label htmlFor="name">Company name {<ErrorMessage name="company" component="span" className="text-danger" />}</label>
                                        <input {...field} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                    </div>
                                </div>
                            )}
                        </Field>

                        <Field name="email">
                            {({ field }) => (
                                <div>
                                    <div className="popup-sign-item">
                                        <label htmlFor="name">Email {<ErrorMessage name="email" component="span" className="text-danger" />}</label>
                                        <input {...field} type="email" className="form-value-color" id="asif-pl" placeholder="" />
                                    </div>
                                </div>
                            )}
                        </Field>

                        <div className="row g-2">
                            <div className="col-4">
                                <Field name="areaCode">
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label htmlFor="name">Area code {<ErrorMessage name="areaCode" component="span" className="text-danger" />}</label>
                                            <div className="popup-pass-show">
                                                <input {...field} type="text" name="areaCode" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-8">
                                <Field name="phone">
                                    {({ field }) => (
                                        <div className="popup-sign-item">
                                            <label htmlFor="phpne">Phone {<ErrorMessage name="phone" component="span" className="text-danger" />}</label>
                                            <div className="popup-pass-show">
                                                <input {...field} type="text" name="phone" id="asif-pl" placeholder="" />
                                            </div>
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div>

                        <Field name="country" initialValue={1}>
                            {({ field }) => (
                                <div className="popup-sign-item">
                                    <label>Country {<ErrorMessage name="country" component="span" className="text-danger" />}</label>
                                    <select {...field}>
                                        {countries.map((country) => (
                                            <option key={'country' + country.id} value={country.id}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </Field>

                        <div className="popup-sign-in-submite wcl-btn">
                            <button type="submit" disabled={isSubmitting}>Apply</button>
                        </div>
                    </form>
                )}
            </Formik>
        </Fragment>
    );
};