import React, { Suspense } from "react";
import { Spinner } from "../components/common/Spinner";
import { AffiliateForm } from "./components/AffiliteForm";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Affiliate: React.FC = () => {

    return (
        <div className="wlc-bg1">
            <HelmetProvider>
                <Helmet>
                    <title>nfty marketplace</title>
                    <meta name="description" content="Instant, borderless real estate - nfty" />
                    <meta property="og:title" content="nfty marketplace" />
                    <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                </Helmet>
            </HelmetProvider>
            <span className="wel-page-left-arrow mobile-left-arrow comin-mobile-left-arrow"><img
                src="https://marketplace.nfty.property/img/mobile-left-arrow.png" className="img-fluid" alt="" /></span>

            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <span className="wel-page-left-arrow">
                            <img src="https://marketplace.nfty.property/img/us-left.png" className="img-fluid" alt="" />
                        </span>

                        <div className="sign-in-popup-form">
                            <div className="popup-form-title">
                                <h2>Congratulations!</h2>
                                <p style={{ textAlign: 'left' }}>You’re a revolutionary!</p>
                                <p style={{ textAlign: 'left' }}>You’ve taken the first step with the biggest update to
                                    the Real Estate industry since the invention of the Internet.</p>
                                <p style={{ textAlign: 'left' }}>In months to come, you’ll look back on this moment and be
                                    fortunate enough to say "I was there first".</p>
                                <p style={{ textAlign: 'left' }}>To learn why it’s inevitable, that asset ownership will
                                    be digitised, and how you can be the first to benefit from it, get in touch below,
                                    and start your journey to becoming, a truly <i>nfty</i> realtor!</p>
                            </div>
                            <Suspense fallback={<Spinner />}>
                                <AffiliateForm />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
};