import React, { useEffect, useState } from 'react';
import { Property } from "../../../models/Property";
import { API_URL } from "../../../config";
import { Spinner } from "../../common/Spinner";
import { Listing } from "./Listing";
import axiosInstance from '../../../api/axiosConfig';

export const Listings: React.FC = () => {
    const [properties, setProperties] = useState<Property[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axiosInstance.get(API_URL + 'properties')
            .then((res) => {
                setProperties(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching properties:", err);
                setError("An error occurred while fetching properties.");
                setLoading(false);
            });
    }, []);

    if (properties.length < 1) {
        return <Spinner />;
    }

    return (
        <div className="row">
            {properties.map((property) => {
                return (
                    <Listing
                        property={property}
                    />
                );
            })}
        </div>
    );
};
