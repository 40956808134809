import React from "react";

export const Spinner: React.FC = () => {
    return (
        <div className="loading-overlay">
            <div className="loader">
                <div className="border"></div>
                <div className="spinner"></div>
                <div className="text">Loading...</div>
            </div>
        </div>
    );
};
