import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { API_URL } from "../../config";
import { Spinner } from "../common/Spinner";
import {EURformatter, formatCurrency} from "../../helpers/formattingHelper";
import { PriceInfo } from "../../models/PriceInfo";
import ReactPlayer from 'react-player';
import axiosInstance from "../../api/axiosConfig";

interface Prices {
    bronze: PriceInfo;
    silver: PriceInfo;
    gold: PriceInfo;
    platinum: PriceInfo;
}

export const GetInvolved: React.FC = () => {
    const [prices, setPrices] = useState<Prices | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axiosInstance.get(API_URL + "notes")
            .then(res => {
                setPrices(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error("Error fetching prices:", err);
                setError("An error occurred while fetching prices.");
                setLoading(false);
            });
    }, []);

    const handleBuyNow = (): void => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const replaceZeros = (number: string): string => {
        return number.replace(".00", "");
    };

    const bronzePrice = formatCurrency(prices?.bronze?.eur || 0, prices?.bronze?.currency);
    const silverPrice = formatCurrency(prices?.silver?.eur || 0, prices?.silver?.currency);
    const goldPrice = formatCurrency(prices?.gold?.eur || 0, prices?.gold?.currency);
    const platinumPrice = formatCurrency(prices?.platinum?.eur || 0, prices?.platinum?.currency);

    const bronzeFormattedPrice = replaceZeros(bronzePrice);
    const silverFormattedPrice = replaceZeros(silverPrice);
    const goldFormattedPrice = replaceZeros(goldPrice);
    const platinumFormattedPrice = replaceZeros(platinumPrice);

    return (
        <>
            {!prices && <Spinner />}
            <div className="get-involved">
                <section className="welcome-page-area">
                    <div className="container">
                        <div className="popup-form-title">
                            <h2>
                                Choose Your <br />nfty Note
                            </h2>
                        </div>
                        <div className="nfty-tokens">
                            <div className="carousel-inner">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-lg-0 mb-4">
                                            <div className="item Cards_gridItemCard">
                                                <div className="image-wrapper">
                                                    <a href="/getInvolved/bronze" onClick={handleBuyNow}>
                                                        <ReactPlayer url="https://marketplace.nfty.property/img/bronze_letterbox.mp4" width="100%" height="auto" playing muted loop playsinline />
                                                    </a>
                                                </div>
                                                <div className="text-wrapper">
                                                    <div className="Cards_horizontalRow">
                                                        <div className="Cards_gridOwner">
                                                            <div className="avatar_visible">
                                                                <img
                                                                    src="https://app.buynfty.com/img/nfty_favicon.png"
                                                                    alt="Bronze Profile" />
                                                            </div>
                                                            <span style={{ marginLeft: '0.5em' }}>nfty Note</span>
                                                        </div>
                                                        <div className="card-title">
                                                            <h2>Bronze</h2>
                                                        </div>
                                                    </div>
                                                    <div className="my-3">
                                                        <h3 className="Cards_gridItemPrice">
                                                            <span className="icon">₳</span>
                                                            <strong>{prices?.bronze?.ada}</strong>
                                                        </h3>
                                                        <div className="Cards_gridItemPriceUSD">
                                                            ({(bronzeFormattedPrice)})
                                                        </div>
                                                    </div>
                                                    <div className="additional-info mb-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Interest Rate:</strong></p>
                                                                    <p><span>10%</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Term:</strong></p>
                                                                    <p><span>12 Months</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Paid:</strong></p>
                                                                    <p><span>Monthly</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/getInvolved/bronze" className="btn btn-primary" onClick={handleBuyNow}>More Info</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-lg-0 mb-4">
                                            <div className="item Cards_gridItemCard">
                                                <div className="image-wrapper">
                                                    <a href="/getInvolved/silver" onClick={handleBuyNow}>
                                                        <ReactPlayer url="https://marketplace.nfty.property/img/silver_cabin.mp4" width="100%" height="auto" playing muted loop playsinline />
                                                    </a>
                                                </div>
                                                <div className="text-wrapper">
                                                    <div className="Cards_horizontalRow">
                                                        <div className="Cards_gridOwner">
                                                            <div className="avatar_visible">
                                                                <img
                                                                    src="https://app.buynfty.com/img/nfty_favicon.png"
                                                                    alt="Silver Profile" />
                                                            </div>
                                                            <span style={{ marginLeft: '0.5em' }}>nfty Note</span>
                                                        </div>
                                                        <div className="card-title">
                                                            <h2>Silver</h2>
                                                        </div>
                                                    </div>
                                                    <div className="my-3">
                                                        <h3 className="Cards_gridItemPrice">
                                                            <span className="icon">₳</span>
                                                            <strong>{prices?.silver?.ada}</strong>
                                                        </h3>
                                                        <div className="Cards_gridItemPriceUSD">
                                                            ({(silverFormattedPrice)})
                                                        </div>
                                                    </div>
                                                    <div className="additional-info mb-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Interest Rate:</strong></p>
                                                                    <p><span>12%</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Term:</strong></p>
                                                                    <p><span>12 Months</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Paid:</strong></p>
                                                                    <p><span>Monthly</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/getInvolved/silver" className="btn btn-primary" onClick={handleBuyNow}>More Info</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-md-0 mb-4">
                                            <div className="item Cards_gridItemCard">
                                                <div className="image-wrapper">
                                                    <a href="/getInvolved/gold" onClick={handleBuyNow}>
                                                        <ReactPlayer url="https://marketplace.nfty.property/img/gold_blue_house.mp4" width="100%" height="auto" playing muted loop playsinline />
                                                    </a>
                                                </div>
                                                <div className="text-wrapper">
                                                    <div className="Cards_horizontalRow">
                                                        <div className="Cards_gridOwner">
                                                            <div className="avatar_visible">
                                                                <img
                                                                    src="https://app.buynfty.com/img/nfty_favicon.png"
                                                                    alt="Gold Profile" />
                                                            </div>
                                                            <span style={{ marginLeft: '0.5em' }}>nfty Note</span>
                                                        </div>
                                                        <div className="card-title">
                                                            <h2>Gold</h2>
                                                        </div>
                                                    </div>
                                                    <div className="my-3">
                                                        <h3 className="Cards_gridItemPrice">
                                                            <span className="icon">₳</span>
                                                            <strong>{prices?.gold?.ada}</strong>
                                                        </h3>
                                                        <div className="Cards_gridItemPriceUSD">
                                                            ({(goldFormattedPrice)})
                                                        </div>
                                                    </div>
                                                    <div className="additional-info mb-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Interest Rate:</strong></p>
                                                                    <p><span>15%</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Term:</strong></p>
                                                                    <p><span>18 Months</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Paid:</strong></p>
                                                                    <p><span>Monthly</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/getInvolved/gold" className="btn btn-primary" onClick={handleBuyNow}>More Info</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-md-0 mb-4">
                                            <div className="item Cards_gridItemCard">
                                                <div className="image-wrapper">
                                                    <a href="/getInvolved/platinum" onClick={handleBuyNow}>
                                                        <ReactPlayer url="https://marketplace.nfty.property/img/platinum_mension.mp4" width="100%" height="auto" playing muted loop playsinline />
                                                    </a>
                                                </div>
                                                <div className="text-wrapper">
                                                    <div className="Cards_horizontalRow">
                                                        <div className="Cards_gridOwner">
                                                            <div className="avatar_visible">
                                                                <img
                                                                    src="https://app.buynfty.com/img/nfty_favicon.png"
                                                                    alt="Platinum Profile" />
                                                            </div>
                                                            <span style={{ marginLeft: '0.5em' }}>nfty Note</span>
                                                        </div>
                                                        <div className="card-title">
                                                            <h2>Platinum</h2>
                                                        </div>
                                                    </div>
                                                    <div className="my-3">
                                                        <h3 className="Cards_gridItemPrice">
                                                            <span className="icon">₳</span>
                                                            <strong>{prices?.platinum?.ada}</strong>
                                                        </h3>
                                                        <div className="Cards_gridItemPriceUSD">
                                                            ({(platinumFormattedPrice)})
                                                        </div>
                                                    </div>
                                                    <div className="additional-info mb-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Interest Rate:</strong></p>
                                                                    <p><span>19%</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Term:</strong></p>
                                                                    <p><span>24 Months</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex justify-content-between">
                                                                    <p><strong>Paid:</strong></p>
                                                                    <p><span>Monthly</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/getInvolved/platinum" className="btn btn-primary" onClick={handleBuyNow}>More Info</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row getinvolved-text">
                            <div className="col-12">
                                <div className="popup-form-title">
                                    <p>Share in the profits from one of the world's most innovative startups!</p>
                                    <p>Enjoy all the advantages of a traditional Convertible Note, with the speed, security and transparency of the blockchain. nfty Notes will either return your full capital in 12 or 24 months, or allow you to convert that capital at preferential rates into actual equity in the nfty marketplace business.</p>
                                    <p>Your nfty Note pays interest directly to your online wallet every month, until it expires. The amount of interest varies with each note, so you can even choose your own level of return.</p>
                                    <p>Purchase multiple notes to trade or sell them online, or keep a single note for yourself to enjoy exclusive owner benefits such as fee-free property investing, access to VIP meetups, priority support, and preferential bidding at nfty.Property.</p>
                                    <p>Ready to join the team? Grab your nfty Note now…</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};