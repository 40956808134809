/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ProcessingAnimation } from "./ProcessingAnimation";

export const ProcessingPaymentModal: React.FC = () => {
    return (
        <div
            className="modal fade"
            id="processingPayment"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="processingPaymentLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="processingPaymentLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <ProcessingAnimation />
                        </div>

                        <div className="send-address text-center">
                            <h2>Processing the payment</h2>
                            <p>
                                It might take up to a few minutes to process your payment. <br />
                                Bear with us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};