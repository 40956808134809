/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface InputsExhaustedNotificationNFTProps {
    retry: () => void;
}

export const InputsExhaustedNotificationNFT: React.FC<InputsExhaustedNotificationNFTProps> = ({ retry }) => {

    return (
        <div className="alart-s notification" id="inputsExhaustedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.nfty.property/img/alart-red.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#inputsExhaustedNotification').fadeOut();
                            }} />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>Not enough UTXOs</h3>
                        <p>
                            The selected NFT is not in the wallet/there is not enough collateral or previous transaction has not yet
                            been processed by the blockchain.
                        </p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a onClick={retry}>
                        Retry
                    </a>
                </div>
            </div>
        </div>
    );
};