/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface PaymentCompleteModalProps {
    type: string;
}

export const PaymentCompleteModal: React.FC<PaymentCompleteModalProps> = ({type}) => {

    const renderText = () => {

        if (type === 'pledge') {
            return (
                <div className="send-address text-center popup-4p">
                    <h2>Congratulations!</h2>
                    <p>
                        You’ve made a successful pledge. Stay tuned and we’ll let you
                        know <br/> as soon as you’ve got your very own piece of NFTY
                        property!
                    </p>
                </div>
            );
        } else if (type === 'listing') {
            return (
                <div className="send-address text-center popup-4p">
                    <h2>Transaction Completed</h2>
                    <p>
                        Your transaction is completed.<br/>
                        The property will be listed shorty.
                    </p>
                </div>
            );
        }

        return (
            <div className="send-address text-center popup-4p">
                <h2>Transaction Completed</h2>
                <p>
                    Your transaction is completed. Enjoy your <br/>
                    purchase. Thanks.
                </p>
            </div>
        );

    }

    return (
        <div
            className="modal fade"
            id="paymentComplete"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="paymentCompleteLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="paymentCompleteLabel"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-x"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <img
                                src="https://marketplace.nfty.property/img/img/qr2s.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                        {renderText()}
                        <div className="popup-done">
                            <a data-bs-dismiss="modal" aria-label="Close">
                                Done
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};