/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface PaymentCancelledNotificationProps {
    retry: () => void;
}

export const PaymentCancelledNotification: React.FC<PaymentCancelledNotificationProps> = ({ retry }) => {
    return (
        <div className="alart-s notification" id="paymentCancelledNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#paymentCancelledNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>Payment cancelled</h3>
                        <p>The payment has been cancelled.</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a onClick={retry}>
                        Retry
                    </a>
                </div>
            </div>
        </div>
    );
};