/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from "../../config";
import { LoginModal } from "../login/LoginModal";
import { MenuItem } from "../../models/MenuItem";
import axiosInstance from '../../api/axiosConfig';

export const Header: React.FC = () => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {
        axiosInstance.get(API_URL + 'menu')
            .then((response) => {
                setMenuItems(response.data);
            })
            .catch((error) => {
                console.error('Error fetching menu items:', error);
            });
    }, []);

    const logout = (): void => {
        axiosInstance.post(API_URL + 'logout')
        .catch((err) => {
            console.error('Logout error:', err);
        })
        .finally(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            //window.location.reload();
        });

        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.reload();
    };

    return (
        <Fragment>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <strong className="offcanvas-title" id="offcanvasRightLabel"></strong>
                    <a href='/#' className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
                        <span aria-hidden="true" style={{ fontSize: "0" }}>Close</span>
                    </a>
                </div>
                <div className="offcanvas-body">
                    <div className="mobile-menu">
                        <ul>
                            <li><a href={'https://nfty.property/'}>Home</a></li>
                            {menuItems.map(menu => {
                                return (
                                    <li key={menu.name}><a href={menu.url}>{menu.name}</a></li>
                                )
                            })}
                            {!localStorage.getItem("token") &&
                                <li><a href="" onClick={(e) => {
                                    e.preventDefault();
                                    $('#loginModal').modal('show');
                                }}>Log in</a></li>
                            }
                            {localStorage.getItem("token") &&
                                <li><a href="" onClick={logout}>Log out</a></li>
                            }
                        </ul>
                    </div>
                    <div className="monile-btn">
                        <Link to={'register'}>Join us</Link> <br />
                        <Link to={'affiliate'}>Become an affiliate</Link>
                    </div>
                </div>
            </div>

            <header className="h-padding">
                <div className="logo-full-container">
                    <a href={'https://nfty.property'}>
                        <img src="https://marketplace.nfty.property/img/logo.svg" width={144} alt="" />
                    </a>
                </div>

                <div className="container">
                    <nav className="project-page-nav">
                        <div className="logo">
                            <Link to={'/'}><img src="https://marketplace.nfty.property/img/logo.svg" width={144} className="img-fluid" alt="" /></Link>
                        </div>
                        <div className="menu-btn">
                            <a className="howtoguide" href="https://nfty.property/how-to-buy/" title="How to guide">
                                How-To Guide
                            </a>
                            {!localStorage.getItem("token") &&
                                <Fragment>
                                    <Link to={'/'}>Marketplace</Link>
                                    <Link to={'register'} className="price-pg-btn">Join us</Link>
                                    <a href="" className={'login'} onClick={(e) => {
                                        e.preventDefault();
                                        $('#loginModal').modal('show');
                                    }}>Login</a>
                                </Fragment>
                            }
                            {localStorage.getItem("token") &&
                                <Fragment>
                                    <Link to={'/'} className="marketplace">Marketplace</Link>
                                    <Link to={'profile'} className="profile">My Profile</Link>
                                    <Link to={'myNfties'} className="properties">My nftys</Link>
                                    <a href="" onClick={logout} className="logout">Log out</a>
                                </Fragment>
                            }
                            <a href='/#' className="menu-icon" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                                className="fa-solid fa-bars"></i></a>
                        </div>
                    </nav>
                </div>

            </header>
            <LoginModal />
        </Fragment>
    );
};