/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_URL } from "../../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { LoginValues } from "../../models/LoginValues";

interface LoginModalFormValues {
    email: string;
    password: string;
}

export const LoginModal: React.FC = () => {
    const [errors, setErrors] = useState<string[]>([]);
    const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
    const [modalVisible, setModalVisible] = useState<boolean>(true);

    const onSubmit = async (values: LoginModalFormValues): Promise<void> => {
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        fetch(API_URL + 'auth/email', {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                return response.json();
            })
            .then((values) => {
                console.log(values);
                if (values?.error) {
                    if (values.error === 'INVALID_CREDIDENTIALS') {
                        setErrors(['Invalid credentials']);
                    } else if (values.error === 'UNVERIFIED') {
                        setErrors(['Your account has not been verified. Please contact info@nfty.property to get verified']);
                    } else if (values.error === 'EMAIL_UNVERIFIED') {
                        setErrors(['Your e-mail has not been verified. Please check your e-mail account for a verification link']);
                    }
                } else {
                    localStorage.setItem('token', values.token);
                    window.location.reload();
                }
            });
    };

    const onForgotPasswordClick = () => {
        setModalVisible(false);
        $('#loginModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    };

    return (
        <div className={`modal fade ${modalVisible ? 'show' : ''}`} id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
            aria-labelledby="loginModal" aria-hidden={!modalVisible}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="loginModal"></strong>
                        <a data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-x"></i></a>
                    </div>
                    <div className="modal-body">
                        <div className="sign-in-popup-form">
                            {errors.length > 0 &&
                                <div className="alert alert-danger" role="alert">
                                    {
                                        errors.map(error => <span key={'error' + error}>{error}</span>)
                                    }
                                </div>
                            }
                            <div className="popup-form-title">
                                <h2>Sign in</h2>
                                <p style={{ textAlign: 'left' }}>Welcome back!</p>
                                <p style={{ textAlign: 'left' }}>Let's get some of that nice passive income rolling in, shall we?</p>
                            </div>
                            <Formik
                                initialValues={{ email: "", password: "" }}
                                validate={(values: LoginModalFormValues) => {
                                    const errors = {} as LoginValues;
                                    if (!values.email) {
                                        errors.email = "Required";
                                    }
                                    if (!values.password) {
                                        errors.password = "Required";
                                    }
                                    return errors;
                                }}
                                onSubmit={onSubmit} >
                                {({ handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>

                                        <Field name="email">
                                            {({ field }) => (
                                                <div className="popup-sign-item">
                                                    <label htmlFor="email">Email {<ErrorMessage name="email" component="span" className="text-danger" />}</label>
                                                    <input {...field} type="email" name="email" className="form-value-color" id="asif-pl" placeholder="" />
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="password">
                                            {({ field }) => (
                                                <div className="popup-sign-item">
                                                    <label htmlFor="password">Password {<ErrorMessage name="password" component="span" className="text-danger" />}</label>
                                                    <div className="popup-pass-show">
                                                        <input {...field} type={passwordType} name="password" className="mb-0" id="asif-pl" placeholder="" />
                                                        <div className="input-group-append toggle-password show-pass" onClick={() => {
                                                            setPasswordType(passwordType === 'password' ? 'text' : 'password');
                                                        }}>
                                                            <span className={passwordType === 'password' ? 'input-group-text mdi mdi-eye-outline' : 'input-group-text mdi mdi-eye-off-outline'}></span>
                                                        </div>
                                                    </div>
                                                    <Link to={'forgot'} onClick={onForgotPasswordClick}>Forgot your password?</Link>
                                                </div>
                                            )}
                                        </Field>
                                        <div className="popup-sign-in-submite wcl-btn">
                                            <button type="submit" disabled={isSubmitting}>Login</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
