/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface WalletUnauthorizedNotificationProps {
    retry: () => void;
    name: string;
}

export const WalletUnauthorizedNotification: React.FC<WalletUnauthorizedNotificationProps> = ({ retry, name }) => {
    function capitalizeFirstLetter(string: string | undefined): string {
        if (!string) {
            return '';
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="alart-s notification" id="walletUnauthorizedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#walletUnauthorizedNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content" style={{ paddingLeft: '10px' }}>
                        <h3>Wallet unauthorized</h3>
                        <p>You need to authorize nfty to use {capitalizeFirstLetter(name)} wallet on the platform.</p>
                    </div>
                </div>
                <div className="bottom-alart">
                    <a onClick={retry}>
                        Retry
                    </a>
                </div>
            </div>
        </div>
    );
};