import React from "react";

export const AccessDeniedNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="accessDeniedNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img src="https://marketplace.nfty.property/img/alart-red.png" className="img-fluid" alt=""
                            onClick={() => {
                                $('#accessDeniedNotification').fadeOut();
                            }} />
                    </div>
                    <div className="alart-content" style={{ marginLeft: '10px' }}>
                        <h3>Access denied</h3>
                        <p>You don't have access to the selected NFT</p>
                    </div>
                </div>
            </div>
        </div>
    );
};