import React from "react";

export const MinSumNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="minSumNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#minSumNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>Sale sum error</h3>
                        <p>The sale price has to be at least 100 ADA</p>
                    </div>
                </div>
                <div className="bottom-alart">
                </div>
            </div>
        </div>
    );
};