import React from "react";

export const SamplePropertyNotification: React.FC = () => {

    return (
        <div className="alart-s notification" id="samplePropertyNotification" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            alt=""
                            onClick={() => {
                                $('#samplePropertyNotification').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>Oops...!</h3>
                        <p>This property is a sample. It cannot be bought.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};