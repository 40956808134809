/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, ChangeEvent } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { API_URL } from "../../config";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Country } from "../../models/Country";
import { User } from "../../models/User";
import { Document } from "../../models/Document";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";

interface ProfileFormValues {
    currentPassword: string;
    password: string;
    confirmPassword: string;
    documentName: string;
    selectedFile: File | string;
}

interface UploadDocumentValues {
    documentType: number;
    selectedFile: File;
}

export const Profile: React.FC = () => {

    const navigate = useNavigate();
    const [userData, setUserData] = useState<User | null>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleMenuClick = (index: number): void => {
        setActiveIndex(index);

        const tabs = document.querySelectorAll('.tab');
        tabs.forEach((tab, i) => {
            if (i !== index) {
                tab.classList.remove('activeOption');
            }
        });

        const profileContent = document.querySelector('.profile-content');

        if (profileContent) {
            profileContent.classList.toggle('slide-up');
        }

        const contentElement = document.getElementById(['intro', 'chngepwd', 'mydocs'][index]);
        if (contentElement) {
            const headerHeight = 1;
            const scrollPosition = contentElement.offsetTop - headerHeight;
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
    };

    const [isMobileEditable, setIsMobileEditable] = useState(false);
    const [mobileValue, setMobileValue] = useState('');
    const [mobileOriginalValue, setMobileOriginalValue] = useState('');
    const [isAddressEditable, setIsAddressEditable] = useState(false);
    const [addressValue, setAddressValue] = useState<string | null>(null);
    const [addressOriginalValue, setAddressOriginalValue] = useState('');
    const [townValue, setTownValue] = useState<string | null>(null);
    const [cityValue, setCityValue] = useState<string | null>(null);
    const [postcodeValue, setPostcodeValue] = useState<string | null>(null);
    const [countryValue, setCountryValue] = useState('');
    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {

        setMobileOriginalValue(userData?.phone ?? '');
        setMobileValue(userData?.phone ?? '');

        setAddressValue(userData?.address ?? null);
        setTownValue(userData?.town ?? null);
        setCityValue(userData?.city ?? null);
        setPostcodeValue(userData?.postcode ?? null);
        setCountryValue(userData?.country_id?.toString() ?? '');

        let address = '';

        if (userData?.address && userData.address !== 'null') {
            address += userData.address + ' ';
        }

        if (userData?.town && userData.town !== 'null') {
            address += userData.town + ' ';
        }

        if (userData?.city && userData.city !== 'null') {
            address += userData.city + ' ';
        }

        if (userData?.country_id) {
            const foundCountry = countries.find(country => country.id == userData.country_id);
            if (foundCountry) {
                address += foundCountry.name;
            }
        }

        setAddressOriginalValue(address);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    const handleMobileInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setMobileValue(event.target.value);
    };

    const handleMobileEditClick = (): void => {
        setIsMobileEditable(true);
        setMobileOriginalValue(mobileValue);
    };

    const handleMobileSaveClick = (): void => {
        setIsMobileEditable(false);
        submitUpdate({
            phone: mobileValue
        });
    };

    const handleMobileCancelClick = (): void => {
        setIsMobileEditable(false);
        setMobileValue(mobileOriginalValue);
    };

    const handleAddressInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setAddressValue(event.target.value);
    };

    const handleAddressEditClick = (): void => {
        setIsAddressEditable(true);
        if (addressValue !== null) {
            setAddressOriginalValue(addressValue);
        }
    };

    const handleAddressSaveClick = (): void => {
        setIsAddressEditable(false);
        //setAddressValue(`${addressValue}, ${townValue}, ${cityValue}, ${postcodeValue}, ${countryValue}`);
        submitUpdate({
            address: addressValue,
            town: townValue,
            city: cityValue,
            postcode: postcodeValue,
            country_id: countryValue
        });
    };

    const handleAddressCancelClick = (): void => {
        setIsAddressEditable(false);
        /*setAddressValue(userData?.address);
        setTownValue(userData?.town);
        setCityValue(userData?.city);
        setPostcodeValue(userData?.postcode);
        setCountryValue(userData?.country_id); */
    };

    const handleTownInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setTownValue(event.target.value);
    };

    const handleCityInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setCityValue(event.target.value);
    };

    const handlePostcodeInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setPostcodeValue(event.target.value);
    };

    const handleCountryInputChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        setCountryValue(event.target.value);
    };

    const updateData = () => {

        fetch(API_URL + "profile?token=" + localStorage.getItem("token")).then((response) => {
            return response.json();
        }).then((response) => {
            setUserData(response);
        }).catch(() => {
            //navigate('/');
        });

    }

    useEffect(() => {

        axiosInstance.get("countries")
            .then(res => setCountries(res.data))
            .catch(err => console.error(err));

        const token = localStorage.getItem("token");

        if (token) {
            axiosInstance.get(`profile?token=${token}`)
                .then(res => setUserData(res.data))
                .catch(() => {
                    localStorage.removeItem("token");
                    navigate('/login');
                });
        } else {
            navigate('/login');
        }

    }, [navigate]);

    const onSubmit = async (values: any): Promise<void> => {

        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        axiosInstance.post(`profile?token=${localStorage.getItem("token")}`, formData)
            .then(res => res.data)
            .then(values => {
                if (values?.status !== 'ok') {
                    if (values?.error === 'INVALID_PASSWORD') {
                        setErrors(['Invalid current password']);
                    } else {
                        setErrors(values);
                    }
                } else {
                    setErrors([]);
                    setSuccess(true);
                }
            });

    };

    const onAddDocumentSubmit = async (values: UploadDocumentValues) => {

        try {
            const formData = new FormData();
            formData.append('documentType', values.documentType.toString());
            formData.append('selectedFile', values.selectedFile);

            const response = await fetch(API_URL + 'profile/document', {
                method: 'POST',
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const result = await response.json();

            if (result.status === 'ok') {
                updateData();
            } else {
                console.error('Document upload failed:', result.error);
            }
        } catch (error) {
            console.error('An error occurred during document upload:', error);
        }

    };

    const submitUpdate = async (values: any): Promise<void> => {

        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        axiosInstance.post(`profile/contact?token=${localStorage.getItem("token")}`, formData)
            .then(res => res.data)
            .then(values => {
                if (values?.status === 'ok') {
                    setUserData(values.user);
                }
            });

    };

    return (
        <div className="wlc-bg1">
            <HelmetProvider>
                <Helmet>
                    <title>nfty marketplace</title>
                    <meta name="description" content="Instant, borderless real estate - nfty" />
                    <meta property="og:title" content="nfty marketplace" />
                    <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                </Helmet>
            </HelmetProvider>
            <section className="welcome-page-area">
                <div className="container">
                    <div className="popup-form-title">
                        <h2>Profile</h2>
                    </div>
                    <div className="content">
                        <span className="wel-page-left-arrow">
                            <img src="https://marketplace.nfty.property/img/us-left.png" className="img-fluid" alt="" />
                        </span>
                        <div className="row">
                            <div className="profile-menu col-md-3 col-lg-3 d-none d-sm-block">
                                <div className="profile-menu-inner">
                                    <ul>
                                        <li>
                                            <a className={`tab ${activeIndex === 0 ? 'activeOption' : ''}`} data-href="#intro"
                                                href="" onClick={(e) => { e.preventDefault(); handleMenuClick(0); }} title="Contact information">Contact
                                                information</a>
                                        </li>
                                        <li>
                                            <a className={`tab ${activeIndex === 1 ? 'activeOption' : ''}`} data-href="#chngepwd"
                                                href="" onClick={(e) => { e.preventDefault(); handleMenuClick(1); }} title="Change password">Change
                                                password</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="welcome-page profile-content col-md-9 col-lg-9">
                                <div className="content-inner">
                                    <div id="intro" className="profile-item">
                                        {userData?.is_verified === 0 &&
                                            <div className="alert alert-warning" role="alert">
                                                Your account is not verified yet. Click <Link to={'/verify'}>here</Link> to get verified
                                            </div>
                                        }
                                        <div className="popup-form-title">
                                            <p style={{ textAlign: 'left' }}></p>
                                            <h3>Contact Information<br /><br /></h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img src="../img/avatar.jpeg" alt="avatar" className="rounded-circle img-fluid"
                                                            style={{ width: '150px' }} />
                                                        <h5 className="my-1">{userData?.name}</h5>
                                                        <p className="text-muted mb-3"></p>
                                                        {/*<div className="d-flex justify-content-center mb-2">
                              <button type="button" className="btn btn-marketplace">Follow</button>
                              <button type="button" className="btn btn-outline-marketplace ms-1">Message</button>
                            </div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Full Name</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">{userData?.name}</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Email</p>
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <p className="text-muted mb-0">{userData?.email}</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row align-items-center">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Phone</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                {isMobileEditable ? (
                                                                    <input
                                                                        type="text"
                                                                        value={mobileValue}
                                                                        onChange={handleMobileInputChange}
                                                                        className="form-control"
                                                                    />
                                                                ) : (
                                                                    <p className="text-muted mb-0">{mobileValue}</p>
                                                                )}
                                                            </div>
                                                            <div className="col-sm-3">
                                                                {isMobileEditable ? (
                                                                    <div className="d-flex justify-content-end">
                                                                        <button className="btn btn-primary me-2" onClick={handleMobileSaveClick}>Save
                                                                        </button>
                                                                        <button className="btn btn-secondary" onClick={handleMobileCancelClick}>Cancel
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button className="btn btn-primary" onClick={handleMobileEditClick}>Edit</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row align-items-center">
                                                            <div className="col-sm-3">
                                                                <p className="mb-0">Address</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                {isAddressEditable ? (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            value={addressValue ?? ''}
                                                                            onChange={handleAddressInputChange}
                                                                            className="form-control mb-3"
                                                                            placeholder="Address"
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            value={townValue ?? ''}
                                                                            onChange={handleTownInputChange}
                                                                            className="form-control mb-3"
                                                                            placeholder="Town"
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            value={cityValue ?? ''}
                                                                            onChange={handleCityInputChange}
                                                                            className="form-control mb-3"
                                                                            placeholder="City"
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            value={postcodeValue ?? ''}
                                                                            onChange={handlePostcodeInputChange}
                                                                            className="form-control mb-3"
                                                                            placeholder="Postcode"
                                                                        />
                                                                        <select className="form-control" id="country" value={countryValue} onChange={handleCountryInputChange}>
                                                                            <option value="" disabled>Pick a country</option>
                                                                            {countries.map(country =>
                                                                            (<option key={"country" + country?.id}
                                                                                value={country.id}>{country?.name}</option>)
                                                                            )}
                                                                        </select>
                                                                    </>
                                                                ) : (
                                                                    <p className="text-muted mb-0">{addressOriginalValue}</p>
                                                                )}
                                                            </div>
                                                            <div className="col-sm-3">
                                                                {isAddressEditable ? (
                                                                    <div className="d-flex justify-content-end">
                                                                        <button className="btn btn-primary me-2" onClick={handleAddressSaveClick}>Save
                                                                        </button>
                                                                        <button className="btn btn-secondary" onClick={handleAddressCancelClick}>Cancel
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <button className="btn btn-primary" onClick={handleAddressEditClick}>Edit</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="chngepwd" className="sign-in-popup-form profile-item">
                                        <div className="popup-form-title">
                                            <p style={{ textAlign: 'left' }}></p>
                                            <h3>Change password<br /><br /></h3>
                                        </div>
                                        {errors.length > 0 &&
                                            <div className="alert alert-danger" role="alert">
                                                {
                                                    errors.map(error => <span key={'error' + error}>{error}</span>)
                                                }
                                            </div>
                                        }
                                        {success &&
                                            <div className="alert alert-success" role="alert">
                                                Your password has been changed
                                            </div>
                                        }
                                        <Formik
                                            initialValues={{ currentPassword: "", password: "", confirmPassword: "", }}
                                            name="changePasswordForm"
                                            validate={(values: ProfileFormValues) => {
                                                const errors: Partial<ProfileFormValues> = {};

                                                if (!values.password) {
                                                    errors.password = "Required";
                                                }
                                                if (!values.currentPassword) {
                                                    errors.currentPassword = "Required";
                                                }
                                                if (!values.confirmPassword) {
                                                    errors.confirmPassword = 'Required';
                                                }
                                                if (values.password !== values.confirmPassword) {
                                                    errors.confirmPassword = 'Passwords must match';
                                                }
                                                return errors;
                                            }}
                                            onSubmit={onSubmit} >
                                            {({ handleSubmit, isSubmitting }) => (
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form onSubmit={handleSubmit}>
                                                            <Field name="currentPassword">
                                                                {({ field }) => (
                                                                    <div>
                                                                        <div className="popup-sign-item">
                                                                            <label htmlFor="currentPassword" className="form-label">Current
                                                                                password {<ErrorMessage name="currentPassword" component="span" className="text-danger" />}</label>
                                                                            <input {...field} type="password" className="form-value-color form-control"
                                                                                id="asif-pl" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <Field name="password">
                                                                {({ field }) => (
                                                                    <div>
                                                                        <div className="popup-sign-item">
                                                                            <label htmlFor="password"
                                                                                className="form-label">Password {<ErrorMessage name="password" component="span" className="text-danger" />}</label>
                                                                            <input {...field} type="password" className="form-value-color form-control"
                                                                                id="asif-pl" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <Field name="confirmPassword">
                                                                {({ field }) => (
                                                                    <div>
                                                                        <div className="popup-sign-item">
                                                                            <label htmlFor="confirmPassword" className="form-label">Confirm
                                                                                password {<ErrorMessage name="confirmPassword" component="span" className="text-danger" />}</label>
                                                                            <input {...field} type="password" className="form-value-color form-control"
                                                                                id="asif-pl" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field>

                                                            <div className="popup-sign-in-submite">
                                                                <button type="submit" disabled={isSubmitting}>Update</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};