import React, { useEffect } from "react";
import { API_URL } from "../../config";
import { useParams } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";

interface ConfirmEmailProps { }

export const ConfirmEmail: React.FC<ConfirmEmailProps> = () => {
    const { code } = useParams<{ code: string }>();

    useEffect(() => {
        axiosInstance.post(API_URL + "auth/confirmEmail/" + code)
            .then((response) => {
                console.log("Email confirmed successfully:", response.data);
            })
            .catch((error) => {
                console.error("Error confirming email:", error);
            });
    }, [code]);

    return (
        <div className="container">
            <HelmetProvider>
                <Helmet>
                    <title>nfty marketplace</title>
                    <meta
                        name="description"
                        content="Instant, borderless real estate - nfty"
                    />
                    <meta property="og:title" content="nfty marketplace" />
                    <meta
                        property="og:image"
                        content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp"
                    />
                </Helmet>
            </HelmetProvider>
            <div className="test-street-area">
                <div className="alert alert-success" role="alert">
                    Thank you! Your e-mail address has been confirmed
                </div>
            </div>
        </div>
    );
};