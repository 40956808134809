/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
// import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useNavigate, useParams } from 'react-router';
// import { wrapPromise } from '../../helpers/promiseHelpers';
import { CardanoWallet } from '../../helpers/CardanoWallet';
import { BuyOtherModal } from '../property/componsents/BuyOtherModal';
import { UnauthenticatedWarning } from '../property/componsents/UnauthenticatedWarning';
import { UnverifiedWarning } from '../property/componsents/UnverifiedWarning';
import { ProcessingPaymentModal } from '../property/componsents/ProcessingPaymentModal';
import { PaymentCompleteModal } from '../property/componsents/PaymentCompleteModal';
import { PledgeFiatModal } from '../property/componsents/PledgeFiatModal';
import { NoWalletNotification } from '../property/componsents/NoWalletNotification';
import { PaymentPendingNotification } from '../property/componsents/PaymentPendingNotification';
import { InputsExhaustedNotification } from '../property/componsents/InputsExhaustedNotification';
import { WalletUnauthorizedNotification } from '../property/componsents/WalletUnauthorizedNotification';
import { WrongNetworkNotification } from '../property/componsents/WrongNetworkNotification';
import { PaymentCancelledNotification } from '../property/componsents/PaymentCancelledNotification';
import { GeneralErrorNotification } from '../property/componsents/GeneralErrorNottification';
import { UnauthenticatedModal } from '../login/UnauthenticatedModal';
import { SaleCompleteModal } from '../nft/components/SaleCompleteModal';
import {EURformatter, formatCurrencyWithoutSymbol} from '../../helpers/formattingHelper';
import { Country } from "../../models/Country";
import { NoteTypes } from '../../models/NoteTypes';
import ReactPlayer from 'react-player';
import axiosInstance from '../../api/axiosConfig';
import axios from "axios";

interface BuyNftyNotesProps { }

interface FormValues {
    firstName: string;
    lastName: string;
    address: string;
    areaCode: string;
    phone: string;
    email: string;
}

export const BuyNftyNotes: React.FC<BuyNftyNotesProps> = () => {

    const { type } = useParams();
    const navigate = useNavigate();
    let paymentInterval: NodeJS.Timeout | null = null;

    useEffect(() => {
        const noteTypes = ['bronze', 'silver', 'gold', 'platinum'];
        if (typeof type !== 'undefined' && noteTypes.indexOf(type) < 0) {
            navigate('/getInvolved');
        }
    }, [type, navigate]);

    // const [errors, setErrors] = useState<string[]>([]);
    // const [success, setSuccess] = useState<boolean>(false);
    const [countries, setCountries] = useState<Country[]>([]);
    const [prices, setPrices] = useState<NoteTypes | null>(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('waiting');
    const [qrCode, setQrCode] = useState('');
    const [expiryTime, setExpiryTime] = useState<Date | null>(null);
    const [loading, setLoading] = useState(false);
    const [lastWallet, setLastWallet] = useState('');
    const [currentPaymentId, setCurrentPaymentId] = useState(0);
    // const form = React.useRef();

    useEffect(() => {
        axiosInstance.get(API_URL + "countries").then(
            res => {
                setCountries(res.data);
            }
        ).catch(error => {
            console.error("Error fetching countries:", error);
        });
        getPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (values: any): Promise<void> => { };

    const getPaymentInfo = async (success: any, values: any): Promise<void> => {

        const formData = new FormData();
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        formData.append('type', type);

        axiosInstance
            .post(API_URL + "notes/buy", formData)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }
                return res.data;
            })
            .then((res) => {
                if (res?.id) {
                    setCurrentPaymentId(res.id);
                    setPrices(res.prices);
                    setWalletAddress(res.address);
                    setQrCode(
                        "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=web+cardano:" +
                        res.address +
                        "?amount=" +
                        res.price
                    );
                    setExpiryTime(new Date(res.expires));
                    startTimer(60 * 60, $("#timeLeft"));
                    checkPayment(res.id);
                    success(res.address, res.price);
                    console.log(currentPaymentId);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(loading);
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 401) {
                        $("#unauthenticatedModal").modal("show");
                        setPaymentStatus("unauthorized");
                    } else if (err.response?.status === 403) {
                        $("#unverifiedWarning").fadeIn();
                        setPaymentStatus("unverified");
                    }
                }
            });
    };

    const startTimer = (duration: any, display: any): void => {
        var timer = duration, minutes, seconds;
        setInterval(function () {
            minutes = parseInt((timer / 60).toString(), 10);
            seconds = parseInt((timer / 60).toString(), 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.text(minutes + ":" + seconds);
            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    };

    const getWalletPayment = async (paymentAddress: string, paymentSum: number, walletName: string): Promise<void> => {

        $('.notification').hide();
        setLastWallet(walletName);
        const wallet = new CardanoWallet(walletName);
        const isAvailable = wallet.checkForWallet();

        if (!isAvailable) {
            setLoading(false);
            $('#noWalletNotification').fadeIn();
            return;
        }

        const isEnabled = await wallet.enableWallet();

        if (isEnabled !== true && (isEnabled.status !== true)) {
            setLoading(false);
            $('#walletUnauthorizedNotification').fadeIn();
            return;
        }

        const networkId = await wallet.getNetworkId();

        if (networkId !== 1) {
            setLoading(false);
            $('#wrongNetworkNotification').fadeIn();
            return;
        }

        await wallet.buildSendADATransaction(
            paymentAddress,
            paymentSum
        );

    };

    const flintPayment = async (values: any): Promise<void> => {

        const walletName: string = 'flint';

        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (payment !== undefined) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        }, values)
    };

    const eternlPayment = async (values: any): Promise<void> => {

        const walletName: string = 'eternl';

        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (payment !== undefined) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        }, values)
    };

    const namiPayment = async (values: any): Promise<void> => {

        const walletName: string = 'nami';

        setLoading(true);

        await getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            //const paymentAddress = 'addr1qx9klw2nxfgeru6lw2jtrajgfax7fcjvrjrrejjrmfp5r5ulrwrra6afl9f4x8rgay74p2wjgd82nr0sq25lsfj5zuysjn5sz7';
            //const paymentSum = 52.19;
            try {
                const payment = await getWalletPayment(paymentAddress, paymentSum, walletName);
                if (payment !== undefined) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }
        }, values);
    };

    const otherPayment = async (values: any): Promise<void> => {
        getPaymentInfo(async (paymentAddress: string, paymentSum: number) => {
            setPaymentStatus('pending');
        }, values);
    };

    const handlePaymentResponse = (payment: any): void => {

        setLoading(false);

        if (payment?.code) {
            if (payment?.code === 2) {
                // cancelPayment();
                $('#paymentCancelledNotification').fadeIn();
            }
        } else if (payment?.indexOf('Transaction error') > -1) {
            // cancelPayment();
            if (payment.indexOf('Not enough ADA') > -1 || payment.indexOf('Insufficient') > -1 || payment.indexOf('Exhausted') > -1) {
                $('#inputsExhaustedNotification').fadeIn();//Not enough funds (probably)
            } else {
                $('#generalErrorNotification').fadeIn();//General error?
            }
        } else {
            setPaymentStatus('processing');
            $('#processingPayment').modal('show');
        }
    };

    const handleWalletException = (e: any): void => {

        console.log(e);

        setLoading(false);

        // cancelPayment();

        if ((e?.message?.indexOf('undefined is not an object (evaluating \'this.Nami.isEnabled\')') > -1 || typeof e === 'undefined') && typeof e !== 'string') {
            $('#noWalletNotification').fadeIn();
        } else if (e?.message?.indexOf('Insufficient') > -1 || (typeof e === 'string' && e?.indexOf('Insufficient') > -1) || (typeof e === 'string' && e?.indexOf('Exhausted') > -1)) {
            $('#inputsExhaustedNotification').fadeIn();
        } else {
            if (e?.code === -3) { //Unauthorized
                $('#walletUnauthorizedNotification').fadeIn();
            } else if (e?.code === -15) { //Wrong network
                $('#wrongNetworkNotification').fadeIn();
            } else if (e?.code === 2) { //Declined
                $('#paymentCancelledNotification').fadeIn();
            } else {
                $('#generalErrorNotification').fadeIn();
            }
        }
    };

    const validateEmail = (email: string): boolean => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    };

    const capitalizeFirstLetter = (string: string): string => {
        return string[0].toUpperCase() + string.slice(1);
    };

    const getPrices = (): void => {
        axiosInstance.get(API_URL + "notes")
            .then(res => {
                setPrices(res.data[type]);
            })
            .catch(error => {
                console.error("Error fetching prices:", error);
            });
    };

    const checkPayment = (paymentId: number): void => {
        paymentInterval = setInterval(() => {
            axiosInstance.get(API_URL + "notes/status/" + paymentId)
                .then(res => {
                    if (res.data.status === 'success') {
                        setPaymentStatus('success');
                        $('#processingPayment').modal('hide');
                        $('#saleComplete').modal('show');
                        if (paymentInterval !== null) {
                            clearInterval(paymentInterval);
                        }
                    }
                })
                .catch(error => {
                    console.error("Error checking payment status:", error);
                });
        }, 5000);
    };

    const retryPayment = async (): Promise<void> => {
        const e: any = '';
        if (lastWallet === 'nami') {
            return namiPayment(e);
        } else if (lastWallet === 'yoroi') {
            // return yoroiPayment();
        } else if (lastWallet === 'eternl') {
            return eternlPayment(e)
        } else if (lastWallet === 'flint') {
            return flintPayment(e);
        } else if (lastWallet === 'typhon') {
            // return typhonPayment();
        }
    };

    const getVideoFileName = (type: string): string => {
        switch (type) {
            case 'bronze':
                return 'bronze_letterbox.mp4';
            case 'silver':
                return 'silver_cabin.mp4';
            case 'gold':
                return 'gold_blue_house.mp4';
            case 'platinum':
                return 'platinum_mension.mp4';
            default:
                return '';
        }
    };

    const replaceZeros = (number: string): string => {
        return number.replace(".00", "");
    };

    const number = EURformatter.format(prices?.eur);
    const formattedNumber = formatCurrencyWithoutSymbol(prices?.eur, prices?.currency)

    return (
        <>
            <div className="buy-nfty-tokens">
                <div className="wlc-bg1">
                    <section className="welcome-page-area">
                        <div className="container">
                            <div className="welcome-page mb-5 pb-5">
                                <div className="popup-form-title">
                                    <h2>nfty Notes</h2>
                                    <h5>Enjoy Monthly Income,<br /> With a Generous Slice of Upside</h5>
                                </div>
                                <div className="sign-in-popup-form buynotes-form">
                                    <div className="row g-2 mb-4">
                                        <div className="col-lg-6 d-flex align-items-stretch">
                                            <div className="nfty-info w-100">
                                                <p className="nfty-type">nfty Note: <span>{capitalizeFirstLetter(type)}</span></p>
                                                <p className="price-eur">Price ({prices?.currency?.name}): <span>{formattedNumber}</span></p>
                                                <p className="price-ada">Price* (ADA): <span>{prices?.ada} ADA</span></p>
                                                {type === 'bronze' && (
                                                    <div>
                                                        <p className='interest-rate'>Interest Rate: <span>10%</span></p>
                                                        <p className='interest-paid'>Paid: <span>Monthly</span></p>
                                                        <p className='nfty-term'>Term: <span>12 months</span></p>
                                                        <p className='owner-benefits'>Owner Benefits: <span>Dip your tow in the water with a great rate of return paid monthly to your digital wallet.</span></p>
                                                    </div>
                                                )}
                                                {type === 'silver' && (
                                                    <div>
                                                        <p className='interest-rate'>Interest Rate: <span>12%</span></p>
                                                        <p className='interest-paid'>Paid: <span>Monthly</span></p>
                                                        <p className='nfty-term'>Term: <span>12 months</span></p>
                                                        <p className='owner-benefits'>Owner Benefits: <span>A better rate of return, with the right to convert your investment into real equity in the nfty Marketplace.</span></p>
                                                    </div>
                                                )}
                                                {type === 'gold' && (
                                                    <div>
                                                        <p className='interest-rate'>Interest Rate: <span>15%</span></p>
                                                        <p className='interest-paid'>Paid: <span>Monthly</span></p>
                                                        <p className='nfty-term'>Term: <span>18 months</span></p>
                                                        <p className='owner-benefits'>Owner Benefits: <span>All of the benefits of Silver, PLUS be the first to get offered new listings with fee-free trading on nfty for the term of your nfty Note.</span></p>
                                                    </div>
                                                )}
                                                {type === 'platinum' && (
                                                    <div>
                                                        <p className='interest-rate'>Interest Rate: <span>19%</span></p>
                                                        <p className='interest-paid'>Paid: <span>Monthly</span></p>
                                                        <p className='nfty-term'>Term: <span>24 months</span></p>
                                                        <p className='owner-benefits'>Owner Benefits: <span>All the great Gold benefits, PLUS exclusive access to meet ups and functions, with the ability to convert your investment to equity at half market valuation on expiry.</span></p>
                                                    </div>
                                                )}
                                                <a href="https://marketplace.nfty.property/files/NFTYConvertibleNoteDocumentation.pdf" target="_blank" rel="noreferrer" className="contract-details-link">Offer Details</a>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 d-flex align-items-start order-first order-lg-last">
                                            <div className="nfty-houses d-flex align-items-stretch">
                                                <div className="w-100">
                                                    <ReactPlayer className="card-img-top h-100" url={`https://marketplace.nfty.property/img/${getVideoFileName(type)}`} playing muted loop playsinline title={`${capitalizeFirstLetter(type)}`} width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Form
                                        mutators={{
                                            triggerValidation(fieldsToValidate: string[], state, { changeValue }) {
                                                fieldsToValidate.forEach((fieldName) => {
                                                    changeValue(state, fieldName, (n: any) => n);
                                                });
                                            }
                                        }}
                                        initialValues={{
                                            country: 1
                                        }}
                                        validate={(values: FormValues) => {
                                            const errors: Partial<FormValues> = {};

                                            if (!values.firstName) {
                                                errors.firstName = "Required";
                                            }
                                            if (!values.lastName) {
                                                errors.lastName = "Required";
                                            }
                                            if (!values.address) {
                                                errors.address = "Required";
                                            }
                                            if (!values.areaCode) {
                                                errors.areaCode = "Required";
                                            }
                                            if (!values.phone) {
                                                errors.phone = "Required";
                                            }
                                            if (!values.email) {
                                                errors.email = "Required";
                                            } else {
                                                if (!validateEmail(values.email)) {
                                                    errors.email = 'Invalid e-mail';
                                                }
                                            }
                                            return errors;
                                        }}
                                        onSubmit={onSubmit}
                                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row g-2">
                                                    <div className="col-6">
                                                        <Field name="firstName">
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <div className="popup-sign-item">
                                                                        <label htmlFor="firstName">First name {meta.error && meta.touched &&
                                                                            <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                                        <input {...input} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="lastName">
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <div className="popup-sign-item">
                                                                        <label htmlFor="lastName">Last name {meta.error && meta.touched &&
                                                                            <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                                        <input {...input} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <Field name="email">
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <div className="popup-sign-item">
                                                                <label htmlFor="name">Email {meta.error && meta.touched &&
                                                                    <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                                <input {...input} type="emal" className="form-value-color" id="asif-pl" placeholder="" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="address">
                                                    {({ input, meta }) => (
                                                        <div className="popup-sign-item">
                                                            <label htmlFor="address">Address {meta.error && meta.touched &&
                                                                <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                            <textarea {...input} className="form-value-color" id="address" rows={3} />
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="row g-2">
                                                    <div className="col-4">
                                                        <Field name="areaCode">
                                                            {({ input, meta }) => (
                                                                <div className="popup-sign-item">
                                                                    <label htmlFor="name">Area code {meta.error && meta.touched &&
                                                                        <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                                    <div className="popup-pass-show">
                                                                        <input {...input} type="text" name="text" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-8">
                                                        <Field name="phone">
                                                            {({ input, meta }) => (
                                                                <div className="popup-sign-item">
                                                                    <label htmlFor="phpne">Phone {meta.error && meta.touched &&
                                                                        <span style={{ color: 'red' }}>{meta.error}</span>}</label>
                                                                    <div className="popup-pass-show">
                                                                        <input {...input} type="text" name="text" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <Field name="country">
                                                    {({ input }) => (
                                                        <div className="popup-sign-item mb-4 pb-4">
                                                            <label htmlFor="country">Country</label>
                                                            <select {...input} className="form-select" id="country">
                                                                {countries.map(country =>
                                                                    (<option key={"country" + country.id} value={country.id}>{country.name}</option>)
                                                                )}
                                                            </select>
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="row g-2">
                                                    <div className="col-12 mb-4 pb-4">
                                                        <h6 className='mb-3'>Please signal your understanding and acceptance below to continue.</h6>
                                                        <div className="form-check mb-2">
                                                            <Field name="declare" type="checkbox" validate={value => !value && 'Required'}>
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <input {...input} className="form-check-input" id="declare" />
                                                                        <label className="form-check-label" htmlFor="declare">I declare that I am a
                                                                            qualified investor, that I understand the risks associated with investment, and
                                                                            that holding or trading cryptocurrency is, by its nature, highly volatile.</label>
                                                                        {meta.error && meta.touched && <span style={{ color: 'red' }}>{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="form-check">
                                                            <Field name="agree" type="checkbox" validate={value => !value && 'Required'}>
                                                                {({ input, meta }) => (
                                                                    <div>
                                                                        <input {...input} className="form-check-input" id="agree" />
                                                                        <label className="form-check-label" htmlFor="agree">I understand that the value of
                                                                            all investments can go down, as well as up, and that property is no
                                                                            exception.</label>
                                                                        {meta.error && meta.touched && <span style={{ color: 'red' }}>{meta.error}</span>}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                {values.declare && values.agree && (
                                                    <div>
                                                        <div className="row g-2">
                                                            <div className="col-12 mb-4">
                                                                <h6 className="mt-2 mb-3">Choose a Payment Method</h6>
                                                                <div className="test-stast-right-btn buynfty-notes">
                                                                    <div className="row">
                                                                        <div className="dropdown wallet-selection col-lg-8 mb-2">
                                                                            <button className="button btn dropdown-toggle selection-button" type="button"
                                                                                id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                                aria-expanded="false">Select
                                                                                Wallet <i id="icon" className="fa fa-chevron-down"></i>
                                                                            </button>
                                                                            <div className="dropdown-menu wallet-dropdown px-2 pb-0"
                                                                                aria-labelledby="dropdownMenuButton">
                                                                                <ul className="wallet-dropdownbtns">
                                                                                    <li>
                                                                                        <a className='button btn-nami' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            form.submit()?.then(() => {
                                                                                                namiPayment(form.getState().values);
                                                                                            });
                                                                                        }}> Buy with Nami</a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className='button btn-eternl' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            form.submit()?.then(() => {
                                                                                                eternlPayment(form.getState().values);
                                                                                            });
                                                                                        }}>Buy with Eternl</a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className='button btn-flint' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            form.submit()?.then(() => {
                                                                                                flintPayment(form.getState().values);
                                                                                            });
                                                                                        }}>Buy with Flint</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="col-lg-4 test-stast-right-btn mb-1">
                                                                            <li>
                                                                                <a className={'white button btn-other'} onClick={() => {
                                                                                    form.submit()?.then(() => {
                                                                                        otherPayment(form.getState().values);
                                                                                    });
                                                                                }}>Buy with Other Wallet</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </form>
                                        )}
                                    />
                                    <p className='rate-updateText' style={{ textAlign: 'left', fontStyle: 'italic', marginTop: '8px' }}>*Ada exchange rate is updated every
                                        5 minutes</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {paymentStatus === 'pending' &&
                <BuyOtherModal
                    qrCode={qrCode}
                    wallet={walletAddress}
                    price={String(prices?.ada)}
                    expiry={Number(expiryTime)}
                />
            }
            <UnauthenticatedWarning type={''} />
            <UnverifiedWarning type={''} />
            <ProcessingPaymentModal />
            <PaymentCompleteModal type={'sale'} />
            <PledgeFiatModal />
            <NoWalletNotification />
            <PaymentPendingNotification />
            <InputsExhaustedNotification
                retry={retryPayment} />
            <WalletUnauthorizedNotification
                retry={retryPayment}
                name={lastWallet} />
            <WrongNetworkNotification
                retry={retryPayment} />
            <PaymentCancelledNotification
                retry={retryPayment} />
            <GeneralErrorNotification
                retry={retryPayment} />
            <UnauthenticatedModal />
            <SaleCompleteModal type={'note'} />
        </>
    );
};