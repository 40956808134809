/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface SaleCompleteModalProps {
    type: 'sale' | 'note' | 'other';
}

export const SaleCompleteModal: React.FC<SaleCompleteModalProps> = ({ type }) => {

    const close = () => {
        $('#saleComplete').modal('toggle');
        $('.modal-backdrop').hide();
    }

    return (
        <div className="modal fade" id="saleComplete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
            aria-labelledby="paymentCompleteLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <strong className="modal-title" id="paymentCompleteLabel"></strong>
                        <a data-bs-dismiss="modal" onClick={close} aria-label="Close"><i className="fa-solid fa-x"></i></a>
                    </div>
                    <div className="modal-body">
                        <div className="qr-img text-center pb-4">
                            <img src="https://marketplace.nfty.property/img/img/qr2s.png" className="img-fluid" alt="" />
                        </div>

                        <div className="send-address text-center popup-4p">
                            <h2>Congratulations!</h2>
                            {type === 'sale' && <p>The NFT has been listed for sale</p>}
                            {type !== 'sale' && <p>The transaction processed successfully</p>}
                        </div>

                        <div className="popup-done">
                            <a onClick={close} data-bs-dismiss="modal" aria-label="Close">Done</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};