import React, { Suspense } from "react";
import { tabs } from "../../data/frontpage";
import { Link } from "react-router-dom";
import { InterestForm } from "./components/InterestForm";
import { Spinner } from "../common/Spinner";

interface ComingSoonProps {
    page: string;
}

export const ComingSoon: React.FC<ComingSoonProps> = ({ page }) => {
    const activeTab: number = page === "sell" ? 1 : 2;
    const pageClassName: string = page === "sell" ? "sell-tab" : "rent-tab";

    return (
        <div key={'comingsoon-' + page} className={`wlc-bg1 ${pageClassName}`}>
            <span className="wel-page-left-arrow mobile-left-arrow comin-mobile-left-arrow">
                <img src="https://marketplace.nfty.property/img/mobile-left-arrow.png" className="img-fluid" alt="" />
            </span>

            <section>
                <div className="container">
                    <div className="project-menu coming-soon-menu">
                        <div className="project-m-item1">
                            <strong></strong>
                        </div>
                        <div className="project-m-item2">
                            <ul>
                                {tabs.map((tab, index) => {
                                    const linkClassName: string = activeTab === index ? "active-m" : "";
                                    const listClassName: string = index === tabs.length - 1 ? "p-menu" : "";

                                    return (
                                        <li key={tab.name} className={listClassName}>
                                            <Link to={tab.link} className={linkClassName}>
                                                {tab.name}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="welcome-page-area">
                <div className="container">
                    <div className="welcome-page">
                        <span className="wel-page-left-arrow">
                            <img src="https://marketplace.nfty.property/img/us-left.png" className="img-fluid" alt="" />
                        </span>

                        <div className="sign-in-popup-form">
                            {page === "sell" && (
                                <div className="popup-form-title">
                                    <h2>Coming soon</h2>
                                    <p style={{ textAlign: "left" }}>
                                        Join us now, and soon you’ll be able to list your very own properties and projects right here!
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        <ul style={{ listStyleType: "circle" }}>
                                            <li>Sick of paying huge fees to estate agents and lawyers?</li>
                                            <li>Looking for a way to take cash out of your property without a bank, or loan shark?</li>
                                            <li>Want instant settlements?</li>
                                            <li>Or just market your property... to the world?</li>
                                        </ul>
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        Tell us a little about yourself, and your project, below so we can let you know just how{" "}
                                        <i>nfty</i> it can be!
                                    </p>
                                </div>
                            )}
                            {page !== "sell" && (
                                <div className="popup-form-title">
                                    <h2>Coming soon</h2>
                                    <p style={{ textAlign: "left" }}>
                                        Soon you’ll be able to rent a really <i>nfty</i> home, or office, right here!
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        You’ll pay with your digital wallet, using your crypto, or traditional (fiat) currency.
                                    </p>
                                    <p style={{ textAlign: "left" }}>
                                        If you want to learn more, and hear when the first of our rental properties go live, tell us how
                                        to reach you, and be the first cab off the rank!
                                    </p>
                                </div>
                            )}
                            <Suspense fallback={<Spinner />}>
                                <InterestForm page={page} />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
