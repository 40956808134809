/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import { NoWalletNotification } from "../property/componsents/NoWalletNotification";
import { InputsExhaustedNotification } from "../property/componsents/InputsExhaustedNotification";
import { WalletUnauthorizedNotification } from "../property/componsents/WalletUnauthorizedNotification";
import { WrongNetworkNotification } from "../property/componsents/WrongNetworkNotification";
import { PaymentCancelledNotification } from "../property/componsents/PaymentCancelledNotification";
import { GeneralErrorNotification } from "../property/componsents/GeneralErrorNottification";
import { SetAddressModal } from "./components/SetAddressModal";
import { MyPropertyListing } from "../listings/components/MyPropertyListing";
import { MyNoteListing } from "../listings/components/MyNoteListing";
import { NoNFTsNotification } from "../nft/components/NoNFTsNotification";
import { Spinner } from "../common/Spinner";
import { CardanoWallet } from "../../helpers/CardanoWallet";
import { Note } from "../../models/Note";
import { Property } from "../../models/Property";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";

export const MyNfties: React.FC = () => {

    // const navigate = useNavigate();
    const [address, setAddress] = useState<string | null>(localStorage.getItem('address'));
    const [properties, setProperties] = useState<Property[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastWallet, setLastWallet] = useState<string>('');

    useEffect(() => {
        axiosInstance.get("profile").then((response) => {
            console.log(response.data);
        }).catch(() => {
            // navigate('/');
        });
    }, []);

    useEffect(() => {
        console.log(address);
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const namiConnect = async (): Promise<void> => {
        setLoading(true);
        const walletName: string = 'nami';
        setLastWallet(walletName);
        const walletAddress = await getWalletAddress(walletName);
        if (walletAddress !== undefined) {
            setAddress(walletAddress);
        }
    };

    const eternlConnect = async (): Promise<void> => {
        setLoading(true);
        const walletName: string = 'eternl';
        setLastWallet(walletName);
        const walletAddress = await getWalletAddress(walletName);
        if (walletAddress !== undefined) {
            setAddress(walletAddress);
        }
    };

    const flintConnect = async (): Promise<void> => {
        setLoading(true);
        const walletName: string = 'flint';
        setLastWallet(walletName);
        const walletAddress = await getWalletAddress(walletName);
        if (walletAddress !== undefined) {
            setAddress(walletAddress);
        }
    };

    const getWalletAddress = async (walletName: string): Promise<any> => {

        $('.notification').hide();
        setLastWallet(walletName);

        const wallet = new CardanoWallet(walletName);
        const isAvailable = wallet.checkForWallet();
        if (!isAvailable) {
            setLoading(false);
            $('#noWalletNotification').fadeIn();
            return;
        }

        const isEnabled = await wallet.enableWallet();
        if (isEnabled !== true && (isEnabled.status !== true)) {
            setLoading(false);
            $('#walletUnauthorizedNotification').fadeIn();
            return;
        }

        const networkId = await wallet.getNetworkId();
        if (networkId !== 1) {
            setLoading(false);
            $('#wrongNetworkNotification').fadeIn();
            return;
        }

        return await wallet.getRewardAddresses();
    };

    const loadData = async (): Promise<void> => {
        if (!address) return
        setLoading(true);

        axiosInstance.get(`me/properties/${address}`)
            .then((res) => {
            setLoading(false);
            if (res.data.status === 'ok') {
                setProperties(res.data.properties);
                setNotes(res.data.notes);
            } else {
                $('#noNftsNotification').fadeIn();
            }
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
    };

    const retryConnect = async (): Promise<void> => {
        if (lastWallet === 'nami') {
            return namiConnect();
        } else if (lastWallet === 'eternl') {
            return eternlConnect()
        } else if (lastWallet === 'flint') {
            return flintConnect();
        }
    };

    return (
        <>
            {(notes.length > 0 || properties.length > 0) ? (
                <section className="project-area">
                    <HelmetProvider>
                        <Helmet>
                            <title>nfty marketplace</title>
                            <meta name="description" content="Instant, borderless real estate - nfty" />
                            <meta property="og:title" content="nfty marketplace" />
                            <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                        </Helmet>
                    </HelmetProvider>
                    <span className="pl-bg"><img src="img/us-left.png" className="img-fluid" alt="" /></span>
                    <span className="pl-rr"><img src="img/p-rr.png" className="img-fluid" alt="" /></span>
                    {notes.length > 0 &&
                        <div className="container">
                            <div className="project-m-item1">
                                <h2>My notes</h2>
                            </div>
                            <div className="row" style={{ marginTop: 30 }}>
                                {notes.map((note: Note) => (
                                    <MyNoteListing
                                        key={'note' + note.id}
                                        type={note.type as "bronze" | "gold" | "silver" | "platinum"}
                                        note={note}
                                    />
                                ))}
                            </div>
                        </div>
                    }
                    {properties.length > 0 &&
                        <div className="container">
                            <div className="project-m-item1">
                                <h2>My properties</h2>
                            </div>
                            <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
                                {properties.map((property: Property) => {
                                    return <MyPropertyListing
                                        key={'property' + property.id}
                                        property={property}
                                    />
                                })}
                            </div>
                        </div>
                    }
                </section>
            ) : (
                <section className="project-area">
                    <HelmetProvider>
                        <Helmet>
                            <title>nfty marketplace</title>
                            <meta name="description" content="Instant, borderless real estate - nfty" />
                            <meta property="og:title" content="nfty marketplace" />
                            <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                        </Helmet>
                    </HelmetProvider>
                    <span className="pl-bg"><img src="img/us-left.png" className="img-fluid" alt="" /></span>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src="https://marketplace.nfty.property/img/bro.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 align-middle">
                                <h1 style={{ fontWeight: 'bold' }}>My properties</h1>
                                <p style={{ fontWeight: 400, fontSize: '18px', maxWidth: '80%', marginTop: 10 }}>You need to connect your
                                    wallet or insert the address to see your properties</p>
                                <div style={{ marginTop: '30px' }} className="test-stast-right-btn">
                                    <div className="row">
                                        <div className="dropdown wallet-selection myProperties-wallet col-lg-8 mb-3">
                                            <button className="button btn dropdown-toggle selection-button" type="button"
                                                id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">Select Wallet <i
                                                    id="icon" className="fa fa-chevron-down"></i>
                                            </button>
                                            <div className="dropdown-menu wallet-dropdown px-2 pb-0" aria-labelledby="dropdownMenuButton">
                                                <ul className="wallet-dropdownbtns">
                                                    <li>
                                                        <a className='button btn-nami' onClick={namiConnect}>Connect Nami</a>
                                                    </li>
                                                    <li>
                                                        <a className='button btn-eternl' onClick={eternlConnect}>Connect Eternl</a>
                                                    </li>
                                                    <li>
                                                        <a className='button btn-flint' onClick={flintConnect}>Connect Flint</a>
                                                    </li>

                                                    {/*<li>
                                <a className='button btn-typhon' href="https://typhonwallet.io/#/">Buy with Typhon</a>
                              </li>
                                <li>
                                <a className='button btn-yoroi' href="https://yoroi-wallet.com/#/">Buy with Yoroi</a>
                                </li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                        <ul className="col-lg-4 test-stast-right-btn mb-3">
                                            <li className={'connect-other white button btn-other'}>
                                                <a style={{ color: '#349EA3!important' }} onClick={() => {
                                                    jQuery('#setAddressModal').modal('show');
                                                }}>Insert a wallet address</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            loading && <Spinner />
                        }
                    </div>
                    <NoWalletNotification />
                    <NoNFTsNotification />
                    <InputsExhaustedNotification
                        retry={retryConnect} />
                    <WalletUnauthorizedNotification
                        retry={retryConnect}
                        name={lastWallet} />
                    <WrongNetworkNotification
                        retry={retryConnect} />
                    <PaymentCancelledNotification
                        retry={retryConnect} />
                    <GeneralErrorNotification
                        retry={retryConnect} />
                    <SetAddressModal setAddress={setAddress} />
                </section>
            )}
        </>
    );
};