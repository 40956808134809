/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { API_URL } from '../../config';
import { Spinner } from '../common/Spinner';
import { NoWalletNotification } from '../property/componsents/NoWalletNotification';
import { WalletUnauthorizedNotification } from '../property/componsents/WalletUnauthorizedNotification';
import { WrongNetworkNotification } from '../property/componsents/WrongNetworkNotification';
import { PaymentCancelledNotification } from '../property/componsents/PaymentCancelledNotification';
import { GeneralErrorNotification } from '../property/componsents/GeneralErrorNottification';
import { UnauthenticatedWarning } from '../property/componsents/UnauthenticatedWarning';
import { UnverifiedWarning } from '../property/componsents/UnverifiedWarning';
// import { BuyOtherModal } from '../property/componsents/BuyOtherModal';
import { SellOtherModal } from './components/sellOtherModal';
import { SaleCompleteModal } from './components/SaleCompleteModal';
// import { NoNFTsNotification } from './components/NoNFTsNotification';
import { CardanoWallet } from '../../helpers/CardanoWallet';
import { MinimumPriceNotification } from './components/MinimumPriceNotification';
import { UnlistedNotification } from './components/UnlistedNotification';
import { AccessDeniedNotification } from './components/AccessDeniedNotification';
import { ProcessingPaymentModal } from '../property/componsents/ProcessingPaymentModal';
import { InputsExhaustedNotificationNFT } from './components/InputsExhaustedNotificationNFT';
import { MinSumNotification } from "./components/MinSumNotification";
import axiosInstance from '../../api/axiosConfig';

export type MetadataItem = {
    title: string;
    value: string;
};

export const Nft: React.FC = () => {

    const { nftId } = useParams<{ nftId: string }>();
    let paymentInterval: NodeJS.Timeout | null = null;
    const [nft, setNft] = useState<any | null>(null);
    const [metadata, setMetadata] = useState<MetadataItem[]>([]);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [paymentStatus, setPaymentStatus] = useState<string>('waiting');
    const [qrCode] = useState<string>('');
    const [expiryTime, setExpiryTime] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [lastWallet, setLastWallet] = useState<string>('');
    const [currentPaymentId, setCurrentPaymentId] = useState<number>(0);

    const updateData = (): void => {
        axiosInstance.get(`nft/${nftId}`).then(response => {
            const res = response.data;
            if (res?.status === 'error') {
                $('noNFTsNotification').fadeIn();
                return;
            }
            setNft(res);
            setMetadata(JSON.parse(res.property.metadata));
        });
    };

    useEffect(() => {
        document.body.classList.add('test-ppi-bg');
        document.querySelectorAll('header')[0].classList.add('test-street-page');
        setTimeout(() => {
            const flickityOptions = {
                // options
                cellAlign: 'left',
                contain: true,
                freeScroll: true,
                wrapAround: true,
                groupCells: 1,
                // groupCells: '100%',
                autoPlay: true,
                // autoPlay: 1500,
                pauseAutoPlayOnHover: false,
                initialIndex: 1,
                prevNextButtons: true,
                navText: [
                    '<img src="https://marketplace.nfty.property/img/cl-arrow.svg" alt="" class="img-fluid">',
                    ' <img src="https://marketplace.nfty.property/img/cl-arrow.svg" class="img-fluid" alt="">'
                ],
                pageDots: false
            };
        }, 1000);

        return () => {
            document.body.classList.remove('test-ppi-bg');
            document.querySelectorAll('header')[0].classList.remove('test-street-page');
        };
    }, []);

    useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!nft) {
        return (<div className={'text-center'}><Spinner /></div>);
    };

    const sellWithNami = async (): Promise<void> => {

        const walletName: string = 'nami';

        setLoading(true);

        getPaymentData(async (walletAddress: string) => {

            const policyId = nft.property.policy_id;
            const assetId = nft.asset_id.split('.')[1];

            try {
                const payment: any = await getWalletPayment(walletAddress, policyId, assetId, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }

        })

    };

    const sellWithEternl = async () => {

        const walletName: string = 'eternl';

        setLoading(true);

        getPaymentData(async (walletAddress: string) => {

            const policyId = nft.property.policy_id;
            const assetId = nft.asset_id.split('.')[1];

            try {
                const payment: any = await getWalletPayment(walletAddress, policyId, assetId, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }

        });

    };

    const sellWithFlint = async (): Promise<void> => {

        const walletName: string = 'flint';

        setLoading(true);

        getPaymentData(async (walletAddress: string) => {

            const policyId = nft.property.policy_id;
            const assetId = nft.asset_id.split('.')[1];

            try {
                const payment: any = await getWalletPayment(walletAddress, policyId, assetId, walletName);
                if (!!payment) {
                    handlePaymentResponse(payment);
                }
            } catch (e) {
                handleWalletException(e);
            }

        });
    };

    const getWalletPayment = async (walletAddress: string, policyId: number, assetId: number, walletName: string): Promise<string> => {

        setLastWallet(walletName);

        const wallet = new CardanoWallet(walletName);
        const isAvailable = wallet.checkForWallet();

        if (!isAvailable) {
            setLoading(false);
            $('#noWalletNotification').fadeIn();
            return '';
        }

        const isEnabled = await wallet.enableWallet();

        if (isEnabled !== true) {
            setLoading(false);
            $('#walletUnauthorizedNotification').fadeIn();
        }

        const networkId = await wallet.getNetworkId();

        if (networkId !== 1) {
            setLoading(false);
            $('#wrongNetworkNotification').fadeIn();
        }

        return wallet.buildSendTokenTransaction(
            walletAddress,
            policyId,
            assetId
        );
    };

    const handlePaymentResponse = (payment: any) => {

        setLoading(false);

        if (payment?.code) {

            if (payment?.code === 2) {
                cancelPayment();
                $('#paymentCancelledNotification').fadeIn();
            }
        } else if (payment?.indexOf('Transaction error') > -1) {

            cancelPayment();

            if (payment.indexOf('Not enough ADA') > -1 || payment.indexOf('Insufficient') > -1) {
                $('#inputsExhaustedNotification').fadeIn();//Not enough funds (probably)
            } else {
                $('#generalErrorNotification').fadeIn();//General error?
            }

        } else {
            setPaymentStatus('processing');
            $('#processingPayment').modal('show');
        }
    };

    const handleWalletException = (e: any): void => {
        console.log('Error', e);
        cancelPayment();
        setLoading(false);

        if ((e?.message?.indexOf('undefined is not an object (evaluating \'this.Nami.isEnabled\')') > -1 || typeof e === 'undefined') && typeof e !== 'string') {
            $('#noWalletNotification').fadeIn();
        } else if (e?.message?.indexOf('Insufficient') > -1 || (typeof e === 'string' && e?.indexOf('Insufficient') > -1) || (typeof e === 'string' && e?.indexOf('Exhausted') > -1)) {
            $('#inputsExhaustedNotification').fadeIn();
        } else {
            if (e?.code === -3) { //Unauthorized
                $('#walletUnauthorizedNotification').fadeIn();
            } else if (e?.code === -15) { //Wrong network
                $('#wrongNetworkNotification').fadeIn();
            } else if (e?.code === 2) { //Declined
                $('#paymentCancelledNotification').fadeIn();
            } else {
                $('#generalErrorNotification').fadeIn();
            }
        }
    };

    const sellWithOther = (): void => {
        getPaymentData(async () => {
            setPaymentStatus('pending');
        })
    };

    const getPaymentData = async (success: any): Promise<boolean> => {
        $('.alart-s').hide();

        if (!localStorage.getItem('token')) {
            setLoading(false);
            $('#unauthenticatedWarning').fadeIn();
            setPaymentStatus('unauthorized');
            return false;
        }

        let price = prompt("Sale price in ADA");

        if (price != null && parseInt(price) > 100) {

            try {
                const res = await axiosInstance.get(`payment/secondaySale/start/${nftId}?price=${price}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    }
                });

                if (res.status !== 200) {
                    const errorResponse = res.data;
                    throw {
                        'code': res.status,
                        'message': errorResponse.message
                    };
                }

                const responseJson = res.data;

                if (responseJson?.status === 'error') {
                    $('#minimumPriceWarning').fadeIn();
                    return false;
                }

                setCurrentPaymentId(responseJson.id);
                setWalletAddress(responseJson.address);
                // setQrCode('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=web+cardano:' + responseJson.address + '?amount=' + responseJson.price);
                setExpiryTime(new Date(responseJson.expires).toISOString());
                startTimer(15 * 60, $('#timeLeft'));
                checkPayment(responseJson.id);
                success(responseJson.address);

                return true;
            } catch (err) {

                setLoading(false);

                if ((err as { code: number }).code === 401) {
                    $('#unauthenticatedWarning').fadeIn();
                    setPaymentStatus('unauthorized');
                } else if ((err as { code: number }).code === 403) {
                    $('#unverifiedWarning').fadeIn();
                    setPaymentStatus('unverified');
                }

                return false;
            }
        } else {
            setLoading(false);
            if (parseInt(price) < 100) {
                $('#minSumNotification').fadeIn();
            }
            return false;
        }
    };

    const unlist = async (): Promise<void> => {
        axiosInstance.post(`payment/unlist/${nft.listing.id}`).then(response => {
            const res = response.data;
            updateData();
            if (res.status === 'success') {
                $('#unlistedNotification').fadeIn();
            } else {
                $('#accessDeniedNotification').fadeIn();
            }
        });
    };

    const checkPayment = (paymentId: number): void => {
        paymentInterval = setInterval(() => {
            axiosInstance.get(`payment/secondaySale/status/${paymentId}`)
                .then(res => res.data)
                .then(res => {
                    if (res.status === 'success') {
                        setPaymentStatus('success');
                        $('#processingPayment').modal('hide');
                        $('#saleComplete').modal('show');
                        if (paymentInterval !== null) {
                            clearInterval(paymentInterval);
                        }
                        updateData();
                    }
                });
        }, 5000);
    };

    const startTimer = (duration: any, display: any): void => {
        var timer = duration, minutes, seconds;
        setInterval(function () {
            minutes = parseInt((timer / 60).toString(), 10);
            seconds = parseInt((timer / 60).toString(), 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            display.text(minutes + ":" + seconds);

            if (--timer < 0) {
                timer = duration;
            }
        }, 1000);
    };

    const retryTransaction = async (): Promise<void> => {
        if (lastWallet === 'nami') {
            return sellWithNami();
        } else if (lastWallet === 'eternl') {
            return sellWithEternl()
        } else if (lastWallet === 'flint') {
            return sellWithFlint();
        }
    };

    const cancelPayment = (): void => {
        if (paymentInterval !== null) {
            clearInterval(paymentInterval);
        }
        axiosInstance.get(`cancel/sale/${currentPaymentId}`)
            .then(res => res.data)
            .then(res => { });
    };

    return (
        <Fragment>
            {loading && <Spinner />}
            <div className="container">
                <div className="test-street-area">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="test-light-box-img">
                                <img src={'https://nftworld.mypinata.cloud/ipfs/' + nft.property.picture1}
                                    className="img-fluid roundedImage" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="test-street-right-item">
                                <div className="test-street-title">
                                    <h2><span> {nft.name}</span></h2>
                                </div>
                                <div className="test-street-nft-icons-area" style={{ flexDirection: 'column' }}>
                                    {metadata.map((value, key) => {
                                        return (<div style={{ paddingTop: '10px' }} className="test-street-nft-icons" key={'trait-' + key}>
                                            <div className="text-nft-icon-content2">
                                                <p style={{ fontWeight: '300', fontSize: '16px' }}>{value.title}: <span style={{ fontWeight: 'bold' }}>{value.value}</span></p>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            {!nft.listing &&
                                <div className="test-stast-right-btn" style={{ marginTop: '35px' }}>
                                    <div className="row">
                                        <div className="dropdown wallet-selection col-lg-8 mb-3">
                                            <button className="button btn dropdown-toggle selection-button" type="button"
                                                id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">Select Wallet <i
                                                    id="icon" className="fa fa-chevron-down"></i>
                                            </button>
                                            <div className="dropdown-menu wallet-dropdown px-2 pb-0" aria-labelledby="dropdownMenuButton">
                                                <ul className="wallet-dropdownbtns">
                                                    <li>
                                                        <a className='button btn-nami' onClick={sellWithNami}> Sell with
                                                            Nami</a>
                                                    </li>
                                                    <li>
                                                        <a className='button btn-eternl' onClick={sellWithEternl}>Sell with
                                                            Eternl</a>
                                                    </li>
                                                    <li>
                                                        <a className='button btn-flint' onClick={sellWithFlint}>Sell with
                                                            Flint</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <ul className="col-lg-4 test-stast-right-btn mb-3">
                                            <li>
                                                <a className={'white button btn-other'} onClick={() => {
                                                    sellWithOther();
                                                }}>Sell with other wallet</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {nft.listing &&
                                <>
                                    <div className="test-stast-right-price">
                                        <p style={{ margin: '20px 0 10px 0px' }}>On sale for</p>
                                        <ul>
                                            <li><span>ADA</span> {nft.listing.price}</li>
                                            <li>/ <span>€</span> {nft.listing.priceEur}</li>
                                        </ul>
                                    </div>
                                    <div className="test-stast-right-btn">
                                        {nft.can_unlist &&
                                            <ul>
                                                <li className='menu-btn'>
                                                    <a className={'white profile m-0'} onClick={unlist}>Unlist the NFT</a>
                                                </li>
                                            </ul>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {paymentStatus === 'pending' &&
                <SellOtherModal
                    qrCode={qrCode}
                    wallet={walletAddress}
                    nftName={nft.asset_id}
                    expiry={Number(expiryTime)}
                />
            }
            <SaleCompleteModal type={'sale'} />
            <UnauthenticatedWarning type={'sell'} />
            <UnverifiedWarning type={''} />
            <NoWalletNotification />
            <InputsExhaustedNotificationNFT
                retry={retryTransaction} />
            <WalletUnauthorizedNotification
                retry={retryTransaction}
                name={lastWallet} />
            <WrongNetworkNotification
                retry={retryTransaction} />
            <PaymentCancelledNotification
                retry={retryTransaction} />
            <GeneralErrorNotification
                retry={retryTransaction} />
            <MinimumPriceNotification />
            <UnlistedNotification />
            <AccessDeniedNotification />
            <ProcessingPaymentModal />
            <MinSumNotification />
        </Fragment>
    );
};