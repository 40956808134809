import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { API_URL } from "../../config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axiosInstance from "../../api/axiosConfig";
// import { useNavigate } from "react-router";

interface ForgotProps { }

interface ForgotPasswordFormValues {
    email: string;
}

export const Forgot: React.FC<ForgotProps> = () => {

    // const navigate = useNavigate();
    // const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState(false);

    const onSubmit = async (values: ForgotPasswordFormValues): Promise<void> => {
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        axiosInstance.post(API_URL + "passwordRecovery", formData)
            .then((response) => {
            if (response.data) {
                setSuccess(true);
            } else {
                console.error("Failed to recover password:", response);
            }
        })
        .catch((error) => {
            console.error("Error recovering password:", error);
        });
    };

    //Taken from https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    const validateEmail = (email: string): boolean => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null;
    };

    return (
        <section className="welcome-page-area">
            <HelmetProvider>
                <Helmet>
                    <title>nfty marketplace</title>
                    <meta name="description" content="Instant, borderless real estate - nfty" />
                    <meta property="og:title" content="nfty marketplace" />
                    <meta property="og:image" content="https://nfty.property/wp-content/uploads/2023/04/h-l.webp" />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className="welcome-page">
                    <span className="wel-page-left-arrow">
                        <img src="https://marketplace.nfty.property/img/img/us-left.png" className="img-fluid" alt="" />
                    </span>

                    <div className="sign-in-popup-form">
                        <div className="popup-form-title">
                            <h2>Password recovery</h2>
                        </div>
                        {success && (
                            <div className="alert alert-success" role="alert">
                                We e-mailed you a link to reset your password
                            </div>
                        )}
                        <Formik
                            initialValues={{ email: "" }}
                            validate={(values: ForgotPasswordFormValues) => {
                                const errors: Partial<ForgotPasswordFormValues> = {};
                                if (!values.email) {
                                    errors.email = "Required";
                                } else {
                                    if (!validateEmail(values.email)) {
                                        errors.email = "Invalid e-mail";
                                    }
                                }
                                return errors;
                            }}
                            onSubmit={onSubmit} >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <div>
                                                <div className="popup-sign-item">
                                                    <label htmlFor="email">
                                                        Email{" "} {<ErrorMessage name="email" component="span" className="text-danger" />}
                                                    </label>
                                                    <input {...field} type="text" className="form-value-color" id="asif-pl" placeholder="" />
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                    <div className="popup-sign-in-submite wcl-btn">
                                        <button type="submit">Reset password</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
};