import React from "react";
import { Link } from "react-router-dom";

interface UnverifiedWarningProps {
    type: string;
}

export const UnverifiedWarning: React.FC<UnverifiedWarningProps> = ({ type }) => {
    const text: string =
        type === 'pledge'
            ? "I'm sorry, it seems you're not yet verified to pledge. To become a verified nfty member, please click "
            : "I'm sorry, it seems you're not yet verified to purchase. To become a verified nfty member, please click ";

    return (
        <div className="alart-s" id="unverifiedWarning" style={{ display: 'none' }}>
            <div className="alart-area">
                <div className="alart-items">
                    <div className="alart-content">
                        <img
                            src="https://marketplace.nfty.property/img/alart-red.png"
                            className="img-fluid"
                            style={{ cursor: 'pointer' }}
                            alt=""
                            onClick={() => {
                                $('#unverifiedWarning').fadeOut();
                            }}
                        />
                    </div>
                    <div className="alart-content">
                        <h3>Unverified</h3>
                        <p>
                            {text} <Link to="/verify">here</Link>
                        </p>
                    </div>
                </div>
                <div className="bottom-alart">{/* Additional content for the bottom if needed */}</div>
            </div>
        </div>
    );
};